<template>
  <f7-page>
    <navbar
      :text="$t('farmSelection_navbar_text')"
      :new-icon-enable="false"
    />
    <div class="container">
      <f7-searchbar
        search-container="#search-farm-list"
        search-item="li"
        search-in=".item-title"
        placeholder="Search"
      />

      <f7-list class="searchbar-not-found">
        <f7-list-item title="Nothing found" />
      </f7-list>

      <f7-list
        id="search-farm-list"
        class="searchbar-found"
        :virtual-list-params="vlParams"
      >
        <ul>
          <f7-list-item
            v-for="(item, index) in JSON.parse(loggedUser).farms"
            :key="index"
            media-item
            radio
            name="demo-radio"
            :title="item.code"
            :value="item.code"
            :style="`top: ${vlData.topPosition}px`"
            @click="changeStatebtnDisable(item)"
          />
        </ul>
      </f7-list>

      <f7-block>
        <f7-button
          name="btnContinue"
          class="botonPrimary"
          col
          button
          button-large
          button-raised
          fill
          :disabled="btnDisable"
          @click="goToTasks()"
        >
          {{ $t("Nfc_navbarpresence_btnContinue") }}
        </f7-button>
      </f7-block>
    </div>
  </f7-page>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import navbar from '../components/NavBar.vue';

export default {
  components: {
    navbar,
  },
  props: {
    employees: { type: Array, default: () => [] },
    callbackSelected: { type: Function, default: () => {} },
  },
  data() {
    return {
      vlData: {
        items: [],
      },
      vlParams: {
        items: this.employees,
        searchAll: this.searchAll,
        renderExternal: this.renderExternal,
        // height: this.$theme.ios ? 63 : (this.$theme.md ? 73 : 46)
      },
      btnDisable: true,
    };
  },
  computed: {
    ...mapState('authentication', ['loggedUser']),
  },
  methods: {
    searchAll(query, items) {
      const result = [];
      const lowerQuery = query.toLowerCase();
      items.forEach((item, i) => {
        if (item.title.toLowerCase().includes(lowerQuery)) {
          result.push(i);
        }
      });
      return result;
    },
    renderExternal(vl, vlData) {
      this.vlData = vlData;
    },
    changeStatebtnDisable(item) {
      this.btnDisable = false;
      this.setFarm(item.code);
      this.setCurrentFarm(item.code);
    },
    ...mapActions('TaskManager', ['setActivityId', 'setActivity', 'setFarm']),
    ...mapActions('AccessControl', ['setCurrentFarm']),
  },
};
</script>
<style lang="scss" scoped>
.block {
  position: absolute;
  bottom: 100px;
  width: 100%;
}
.container {
  background: #f4f5f7;
  padding-top: 10px;
}
.searchbar-input-wrap {
  border: 1px solid #dddddd;
  box-sizing: border-box;
  border-radius: 4px;
}
.searchbar-not-found {
  text-align: center;
}
.list .item-subtitle {
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: #999;
  mix-blend-mode: normal;
  opacity: 0.8;
}
.searchbar-found {
  height: 338px !important;
  overflow: scroll;
  background: white;
}
</style>
