import db from '../../services/Database';

export default {
  getAll() {
    return db.locations.toArray();
  },
  getFilterFarm(farmCode) {
    return db.locations.where('farm').equals(farmCode).toArray();
  },
  async insert(data) {
    await db.locations.add(data);
    return true;
  },
  async getLocationById(idLocation) {
    const locations = await db.locations.filter(
      (element) => element.bd_id === idLocation,
    ).toArray();
    if (locations.length > 0) {
      return locations[0].name;
    }
    return '';
  },
};
