<template>
  <div class="container main-accordion-list">
    <div class="block block-header header-space">
      <f7-row>
        <div class="item-inner">
          <div class="item-cell">
            <div class="block-module">
              <img
                class="icon-line float-left"
                width="40"
                height="40"
                :src="images.workers"
              >
              <span class="number-icon">{{ currentWorkers }}/{{ expectedWorkers }}</span>
            </div>
          </div>
          <div class="item-cell">
            <div class="block-module">
              <img
                class="icon-line float-left"
                width="40"
                height="40"
                :src="images.tasks"
              >
              <span class="number-icon">{{ currentTaskInProgress }}/{{ currentTask }}</span>
            </div>
          </div>
        </div>
      </f7-row>
    </div>

    <!-- Separate collapsibles - omit "accordion-list" class-->
    <f7-list
      class="searchbar-found"
      :virtual-list-params="vlParams"
    >
      <!-- TODO: Esto son los filtros que tenemos que hacer para filtrar en la lista -->
      <!-- <f7-list-item
        accordion-item
        class="select-task"
        name="filterTask"
        :title="$t('TasksList_filterTask_title')"
      >
        <strong>
          <AccordionFilterTask
            :resume-task="resumeTask"
            accordion-item-opened
          />
        </strong>
      </f7-list-item> -->

      <f7-list-item
        v-for="(item, index) in detailTask"
        :key="index"
        accordion-item
        content-accordion
        name="lstInProgressTasks"
        :badge="`${item.tasks.length}`"
        :title="`${item.name}`"
        accordion-item-opened
      >
        <f7-accordion-content>
          <div class="triangle" />
          <f7-list>
            <!-- Aqui carga las tareas -->
            <AccordionTaskItem
              v-for="(item2, index2) in item.tasks"
              :key="index2"
              :item-task="item2"
              :name-location="item.name"
            />
          </f7-list>
        </f7-accordion-content>
      </f7-list-item>
    </f7-list>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import AccordionFilterTask from './AccordionFilterTask.vue';
import AccordionTaskItem from './AccordionTaskItem.vue';
import PlanDaily from '../../json/planDaily.json';
import TasksSync from '../../js/models/TasksSync';

import ImageTask from '../../static/img/ic_task.svg';
import ImageUser from '../../static/img/ic_useron.svg';
import EventBus from '../../js/event-bus';

export default {
  name: 'AccordionList',
  components: {
    AccordionFilterTask,
    AccordionTaskItem,
  },
  props: {
    tasklist: { type: Array, default: () => [] },
    resumeTask: { type: Array, default: () => [] },
  },
  data() {
    return {
      currentTask: 0,
      currentTaskInProgress: 0,
      currentWorkers: 0,
      expectedWorkers: 0,
      vlParams: {
        items: this.tasklist,
        searchAll: this.searchAll,
        renderExternal: this.renderExternal,
      },
      images: {
        tasks: ImageTask,
        workers: ImageUser,
      },
      PlanDaily,
      popupOpened: false,
      newTasks: [],
      currentTasks: [],
      inprogressTasks: [],
      completedTasks: [],
      detailTask: [],
    };
  },
  computed: {
    ...mapState('TaskManager', ['farm', 'activityId']),
  },
  async beforeMount() {
    this.updateList();
    EventBus.$on('refresh-taskslist', this.updateList);
  },
  beforeDestroy() {
    EventBus.$off('refresh-taskslist');
  },
  methods: {
    async updateList() {
      this.$f7.preloader.show();
      try {
        this.newTasks = [];
        this.currentTasks = [];
        this.completedTasks = [];
        this.inprogressTasks = [];
        this.currentWorkers = 0;
        this.expectedWorkers = 0;
        this.currentTaskInProgress = 0;
        this.currentTask = 0;
        this.detailTask = [];

        const tasksSync = await TasksSync.getAll();
        for (let i = 0; i < tasksSync.length; i += 1) {
          if (tasksSync[i].status === 'COMPLETED') {
            this.completedTasks.push({
              name: tasksSync[i].name,
              comment: tasksSync[i].comment,
              task_code: tasksSync[i].task_code,
              workers: tasksSync[i].workers,
              performance: tasksSync[i].performance,
              activity: tasksSync[i].activity,
              activity_code: tasksSync[i].activity_code,
              id: tasksSync[i].id,
              location: tasksSync[i].location_id,
              status: tasksSync[i].status,
              planner: tasksSync[i].planner,
              items: tasksSync[i].items,
              nameLocation: tasksSync[i].location,
              short_id: tasksSync[i].short_id,
              nursery: tasksSync[i].nursery,
            });
            // this.currentWorkers += tasksSync[i].workers.length;
            this.expectedWorkers += this.count(tasksSync[i].planner.workers);
          } else if (tasksSync[i].status === 'OPEN' || tasksSync[i].status === 'PENDING') {
            this.currentTasks.push({
              name: tasksSync[i].name,
              comment: tasksSync[i].comment,
              task_code: tasksSync[i].task_code,
              attachment: tasksSync[i].product_performance,
              attachment_vehicles: tasksSync[i].attachments_vehicles,
              products: tasksSync[i].products,
              workers: tasksSync[i].workers,
              tools: tasksSync[i].tools,
              performance: tasksSync[i].performance,
              activity: tasksSync[i].activity,
              activity_code: tasksSync[i].activity_code,
              id: tasksSync[i].id,
              location: tasksSync[i].location_id,
              status: 'PENDING',
              planner: tasksSync[i].planner,
              items: tasksSync[i].items_drivers,
              area_effective: tasksSync[i].area_effective,
              items_drivers: tasksSync[i].items_drivers,
              nameLocation: tasksSync[i].location,
              short_id: tasksSync[i].short_id,
              nursery: tasksSync[i].nursery,
            });
            this.currentWorkers += this.countIn(tasksSync[i].workers);
            this.expectedWorkers += this.count(tasksSync[i].planner.workers);
            this.currentTask += 1;
          } else if (tasksSync[i].status === 'IN_PROGRESS') {
            this.inprogressTasks.push({
              name: tasksSync[i].name,
              task_code: tasksSync[i].task_code,
              workers: tasksSync[i].workers,
              attachment: tasksSync[i].product_performance,
              attachment_vehicles: tasksSync[i].attachments_vehicles,
              products: tasksSync[i].products,
              tools: tasksSync[i].tools,
              performance: tasksSync[i].performance,
              activity: tasksSync[i].activity,
              activity_code: tasksSync[i].activity_code,
              id: tasksSync[i].id,
              location: tasksSync[i].location_id,
              status: tasksSync[i].status,
              planner: tasksSync[i].planner,
              items: tasksSync[i].items_drivers,
              area_effective: tasksSync[i].area_effective,
              items_drivers: tasksSync[i].items_drivers,
              nameLocation: tasksSync[i].location,
              short_id: tasksSync[i].short_id,
              nursery: tasksSync[i].nursery,
            });
            this.currentWorkers += this.countIn(tasksSync[i].workers);
            this.expectedWorkers += this.count(tasksSync[i].planner.workers);
            this.currentTaskInProgress += 1;
          }
        }
        if (this.currentTasks.length > 0) {
          this.detailTask.push({
            group: 'Grupo',
            name: this.$t('Status_Open'),
            tasks: this.currentTasks,
          });
        }
        if (this.inprogressTasks.length > 0) {
          this.detailTask.push({
            group: 'Grupo',
            name: this.$t('Status_InProgress'),
            tasks: this.inprogressTasks,
          });
        }
        if (this.completedTasks.length > 0) {
          this.detailTask.push({
            group: 'Grupo',
            name: this.$t('Status_Completed'),
            tasks: this.completedTasks,
          });
        }
        // this.vlData = this.detailTask;
      } catch (reason) {
        console.debug(reason);
      } finally {
        this.$f7.preloader.hide();
      }
    },
    count(item) {
      let x = 0;
      for (let i = 0; i < item.length; i += 1) {
        x += item[i].count;
      }
      return x;
    },
    countIn(item) {
      let x = 0;
      for (let i = 0; i < item.length; i += 1) {
        if (item[i].end_date === null) {
          x += 1;
        }
      }
      return x;
    },
    searchAll(query, items) {
      const result = [];
      const lowerQuery = query.toLowerCase();
      items.forEach((item, i) => {
        if (
          item.name.toLowerCase().includes(lowerQuery)
        ) {
          result.push(i);
        }
      });
      return result;
    },
    renderExternal(vl, vlData) {
      this.vlData = vlData;
    },
    itemsCount(array) {
      const count = array.length;
      return count === 1
        ? `${count} ${this.$t('AccordionList_worker')}`
        : `${count} ${this.$t('AccordionList_workers')}`;
    },
    navigateTo(path, item) {
      this.setLocations(item.location);
      this.setLocationName(item.location);
      this.setNewTask(item.task);
      this.setId(item.id);
      this.setShortID(item.short_id);
      this.setTaskName(item.name);
      this.setTaskCode(item.code);
      this.setWorkers(item.workers);
      this.$f7.views.main.router.navigate(path, { reloadCurrent: true });
    },
    irLocationSelect(item) {
      this.setLocations(item.locations);
      this.setNewTask(item.id);
      this.setId(item.id);
      this.setShortID(item.short_id);
      this.setTaskName(item.name);
      this.setTaskCode(item.code);
      this.setTaskUnit(item.unit);
      this.setTaskPrice(item.price);
      // router.navigate('/locationSelect/', { reloadCurrent: true }); Pasa por el LocationPicker
      this.$f7.views.main.router.navigate('/startOrder/', { reloadCurrent: true });
    },
    ...mapActions('TaskManager', [
      'setLocations',
      'setId',
      'setTaskName',
      'setWorkers',
      'setTaskUnit',
      'setTaskPrice',
      'setNewTask',
      'setLocationName',
      'setTaskCode',
    ]),
  },
};
</script>

<style lang="scss">
.main-accordion-list{
  .item-inner{
    .item-title{
      color: #FFFFFF;
      padding-left: 25px;
    }
    .item-after{
      display: flex;
      align-items: center;
    }
  }
  .block-header {
    background: var(--fr-chocolat);
  }
  .block-header .item-inner {
      display: flex;
      flex-direction: row;
  }

  .block-header .item-cell {
    width: 130px;
    margin-right: 20px;
  }

  .block-header.header-space {
    padding: 25px;
  }

  .block-header .number-icon {
    font-size: 22px;
    font-weight: 600;
    line-height: 28px;
    display: inline-block;
    padding: 5px 10px;
    color: whitesmoke;
  }

  div.sheet-modal.modal-in::before {
    position: absolute;
    height: 147px;
    left: 0px;
    top: 493px;
    border-radius: 8px;
  }
}

</style>
