import { render, staticRenderFns } from "./ButtonSync.vue?vue&type=template&id=0ddb3bae&scoped=true&"
import script from "./ButtonSync.vue?vue&type=script&lang=js&"
export * from "./ButtonSync.vue?vue&type=script&lang=js&"
import style0 from "./ButtonSync.vue?vue&type=style&index=0&id=0ddb3bae&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ddb3bae",
  null
  
)

export default component.exports