<template>
  <f7-page
    name="SearchEmployee"
    class="content-manual-list"
  >
    <navbar
      :new-icon-enable="false"
      :path-back="pathBack"
      :text="`${$t('SearchEmployee_title')}`"
    />
    <f7-searchbar
      search-container="#employeevirtual-list"
      search-item="li"
      search-in=".item-title"
      :placeholder="$t('ManualListSearch')"
      :disable-button="!$theme.aurora"
    />
    <div class="container">
      <f7-list
        class="searchbar-not-found"
      >
        <f7-list-item
          :title="$t('ManualList_listItem_title')"
        />
      </f7-list>
      <f7-list
        id="employeevirtual-list"
        class="searchbar-found"
        media-list
        virtual-list
        :virtual-list-params="{
          items,
          fullNameFilter,
          searchAll,
          renderExternal,
          height: $theme.ios ? 63 : ($theme.md ? 73 : 46)
        }"
      >
        <ul>
          <li>
            <div
              v-for="(employee, index) in vlData.items"
              :key="index"
              media-item
              class="item-link item-content no-chevron"
            >
              <div class="item-media">
                <div class="event-type-icon">
                  <img
                    class="float-left icon-status"
                    width="auto"
                    height="20"
                    :src="showImage(employee.event)"
                  >
                  <img
                    class="avatar"
                    width="58"
                    height="58"
                    :src="photoSrc(employee.event)"
                    @error="setImgError"
                  >
                </div>
              </div>
              <div class="item-inner">
                <div class="item-title-row">
                  <div class="item-title">
                    {{ employee.workers.name }}  {{ employee.workers.surname }}
                  </div>
                </div>
                <div
                  class="item-text"
                >
                  {{ employee.location }} → {{ employee.taskName }}
                </div>
                <!--  v-if="item.event.type === 'OUT'" -->
                <div
                  v-if="employee.event === 'OUT'"
                  class="item-subtitle"
                >
                  {{ formattedDate(employee.workers, employee.event) }}
                </div>
                <div
                  v-else
                  class="item-subtitle"
                >
                  {{ formattedDate(employee.workers, employee.event) }}
                </div>
              </div>
              <div class="item-after">
                <span class="hour">{{ formattedHour(employee.workers) }}  </span>
              </div>
            </div>
          </li>
        </ul>
      </f7-list>
    </div>
    <!-- <f7-button
        name="btnContinue"
        class="botonPrimary margin"
        col
        button
        button-large
        button-raised
        fill
        :disabled="btnDisable"
        @click="saveWorker()"
      >
        {{ $t("Nfc_navbarpresence_btnContinue") }}
      </f7-button> -->
  </f7-page>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import moment from 'moment';
import navbar from '../../components/NavBar.vue';
import TasksSync from '../../js/models/TasksSync';
import ImageEdit from '../../static/img/ic_edit-btn-round.svg';
import ImageUser from '../../static/img/ic_user.svg';
import IconScan from '../../static/img/icon-scan.svg';
import IconQr from '../../static/img/ic_qr.svg';
import ImageEnter from '../../static/img/ic_enter_arrow.svg';
import ImageExit from '../../static/img/ic_exit_arrow.svg';
import ImageUnknown from '../../static/img/Unknown.svg';
import imgEnterUser from '../../static/img/ic_user_enter.svg';
import imgExitUser from '../../static/img/ic_user_exit.svg';
import UnknownArrow from '../../static/img/ic_unknown_arrow.png';
import Employees from '../../js/models/Employees';
import Locations from '../../js/models/Locations';

export default {
  components: {
    navbar,
  },
  props: {
    employees: { type: Array, default: () => [] },
    callbackSelected: { type: Function, default: () => {} },
    fullNameFilter: { type: String, default: '' },
    limitFilter: { type: Number, default: 9999 },
    pageFilter: { type: Number, default: 0 },
  },
  data() {
    const items = [];
    return {
      code: '',
      finalLevelLocation: [],
      vlData: {
        items: [],
      },
      items,
      images: {
        edit: ImageEdit,
        user: ImageUser,
        IconScan,
        IconQr,
      },
      vlParams: {
        items: this.employees,
        searchAll: this.searchAll,
        renderExternal: this.renderExternal,
        // height: this.$theme.ios ? 63 : (this.$theme.md ? 73 : 46),
      },
      worker: null,
      btnDisable: true,
      pathBack: '/tasks/list/',
      locationsTree: [],
      addLocation: [],
      currentLocations: '',
    };
  },
  computed: {
    ...mapState('TaskManager', ['id', 'locationId']),
  },
  async beforeMount() {
    this.$f7.preloader.show();
    try {
      this.newTasks = [];
      this.currentTasks = [];
      const xhrFormat = await Employees.getAll();
      this.locationsTree = await Locations.getAll();
      const tasksSync = await TasksSync.getAll();
      for (let i = 0; i < tasksSync.length; i += 1) {
        this.currentLocations = this.getFinalLevelLocation(tasksSync[i].location[0]);
        for (let j = 0; j < tasksSync[i].workers.length; j += 1) {
          if (tasksSync[i].workers[j].end_date !== null) {
            this.newTasks.push({
              id: tasksSync[i].id,
              taskName: tasksSync[i].activity,
              workers: tasksSync[i].workers[j],
              location: this.currentLocations,
              object: tasksSync[i],
              event: 'OUT',
            });
          } else {
            this.newTasks.push({
              id: tasksSync[i].id,
              taskName: tasksSync[i].activity,
              workers: tasksSync[i].workers[j],
              location: this.currentLocations,
              object: tasksSync[i],
              event: 'IN',
            });
          }
          for (let k = 0; k < xhrFormat.length; k += 1) {
            if (tasksSync[i].workers[j].id === xhrFormat[k].id) {
              if (tasksSync[i].workers[j].end_date !== null) {
                this.items.push({
                  id: tasksSync[i].id,
                  taskName: tasksSync[i].activity,
                  workers: tasksSync[i].workers[j],
                  location: this.currentLocations,
                  object: tasksSync[i],
                  event: 'OUT',
                });
              } else {
                this.items.push({
                  id: tasksSync[i].id,
                  taskName: tasksSync[i].activity,
                  workers: tasksSync[i].workers[j],
                  location: this.currentLocations,
                  object: tasksSync[i],
                  event: 'IN',
                });
              }
            }
          }
        }
      }
      // this.vlData.items = this.items;
      this.updateItems(this.items);
    } catch (reason) {
      console.debug(reason);
      this.$f7.dialog.alert('no worker for selected name or surname'); // TODO: Visualizar este error
    } finally {
      this.$f7.preloader.hide();
    }
  },
  methods: {
    locations(item) {
      let aux = '';
      if (item.length > 0) {
        for (let i = 0; i < item.length; i += 1) {
          if (i === 0) aux += `${item[i]}`;
          else aux += `, ${item[i]}`;
        }
      }
      return aux;
    },
    getFinalLevelLocation(item) {
      this.addLocation = [];
      for (let i = 0; i < this.locationsTree.length; i += 1) {
        if (this.locationsTree[i].children.length > 0) {
          for (let j = 0; j < this.locationsTree[i].children.length; j += 1) {
            if (this.locationsTree[i].children[j].children.length > 0) {
              for (let k = 0; k < this.locationsTree[i].children[j].children.length; k += 1) {
                if (this.locationsTree[i].children[j].children[k].children.length > 0) {
                  for (let l = 0; l < this.locationsTree[i].children[j]
                    .children[k].children.length; l += 1) {
                    if (this.locationsTree[i].children[j].children[k].children[l]
                      .children.length > 0) {
                      for (let m = 0; m < this.locationsTree[i].children[j].children[k]
                        .children[l].children.length; m += 1) {
                        if (this.locationsTree[i].children[j].children[k].children[l]
                          .children[m].name === item) {
                          this.addLocation.push(this.locationsTree[i].children[j].children[k].name);
                          for (let n = 0; n < this.addLocation.length - 1; n += 1) {
                            if (this.addLocation[n] === this.addLocation[n + 1]) {
                              this.addLocation.splice(n, 1);
                            }
                          }
                        }
                      }
                    } else if (this.locationsTree[i].children[j].children[k]
                      .children[l].name === item) {
                      this.addLocation.push(this.locationsTree[i].children[j].children[k].name);
                      for (let n = 0; n < this.addLocation.length - 1; n += 1) {
                        if (this.addLocation[n] === this.addLocation[n + 1]) {
                          this.addLocation.splice(n + 1, 1);
                        }
                      }
                    }
                  }
                } else if (this.locationsTree[i].children[j].children[k].name === item) {
                  this.addLocation.push(this.locationsTree[i].children[j].children[k].name);
                  for (let n = 0; n < this.addLocation.length - 1; n += 1) {
                    if (this.addLocation[n] === this.addLocation[n + 1]) {
                      this.addLocation.splice(n + 1, 1);
                    }
                  }
                }
              }
            } else if (this.locationsTree[i].children[j].name === item) {
              this.addLocation.push(this.locationsTree[i].children[j].name);
              for (let n = 0; n < this.addLocation.length - 1; n += 1) {
                if (this.addLocation[n] === this.addLocation[n + 1]) {
                  this.addLocation.splice(n + 1, 1);
                }
              }
            }
          }
        } else if (this.locationsTree[i].name === item) {
          this.addLocation.push(this.locationsTree[i].name);
          for (let n = 0; n < this.addLocation.length - 1; n += 1) {
            if (this.addLocation[n] === this.addLocation[n + 1]) {
              this.addLocation.splice(n, 1);
            }
          }
        }
      }
      this.addLocation = this.addLocation.filter(this.onlyUnique);
      return this.getLocations(this.addLocation);
    },
    onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    },
    getLocations(item) {
      let aux = '';
      if (item.length > 0) {
        for (let i = 0; i < item.length; i += 1) {
          if (i === 0) aux += `${item[i]}`;
          else aux += `, ${item[i]}`;
        }
      }
      return aux;
    },
    formattedDate(item, event) {
      if (event === 'IN') {
        return ` ${this.dateDay(item)} | ${this.formattedHour2(item)}`;
      }
      return ` ${this.dateDay(item)} | ${this.formattedHour2(item)} → ${this.formattedHour3(item)}`;
    },
    formattedHour(item) {
      const momentObj = moment();
      let end = (item.end_date) ? item.end_date : momentObj.format('YYYY-MM-DD HH:mm:ss');
      end = moment.utc(end);
      const ms = moment(end).diff(moment(item.init_date));
      const d = moment.utc(moment.duration(ms).asMilliseconds());
      return moment.utc(d).format('HH:mm');
    },
    formattedHour2(item) {
      const ms = moment(item.init_date);
      return moment.utc(ms).format('HH:mm');
    },
    formattedHour3(item) {
      const ms = moment(item.end_date);
      return moment.utc(ms).format('HH:mm');
    },
    dateDay(item) {
      const aux = new Date(item.init_date);
      return moment(aux).format('DD/MM');
    },
    photoSrc(item) {
      if (item === 'IN') {
        return imgEnterUser;
      }
      if (item === 'OUT') {
        return imgExitUser;
      }
      return ImageUnknown;
    },
    showImage(item) {
      switch (item) {
        case 'IN': {
          return ImageEnter;
        }
        case 'OUT': {
          return ImageExit;
        }
        default: {
          return UnknownArrow;
        }
      }
    },
    setImgError() {
      this.imgError = true;
    },
    searchAll(query, items) {
      const result = [];
      const lowerQuery = query.toLowerCase();
      items.forEach((item, i) => {
        if (item.workers.name.toLowerCase().includes(lowerQuery)
          || item.workers.surname.toLowerCase().includes(lowerQuery)) {
          result.push(i);
        }
      });
      return result;
    },
    renderExternal(vl, vlData) {
      this.vlData = vlData;
    },
    updateItems(items) {
      this.items = items;
      this.vlData.items = items;
      this.$f7.virtualList.get().replaceAllItems(items);
    },
    ...mapActions('TaskManager', ['setWorkers']),
    ...mapActions('worker', ['setSelectedWorker', 'setEvent', 'changeUpSheet', 'setType', 'setCode']),
  },
};
</script>
<style lang="scss" scoped>

.list{
  ul{
    height: 100vh!important;
  }
  .item-content{
    .item-title{
      color: #232323;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
    }
    .item-after{
      padding: 0 10px 0 0;
    }
    .item-text{
      padding-top: 0;
      padding-bottom: 0;
      font-size: 14px;
    }
    .item-subtitle {
      font-weight: 500;
      font-size: 13px;
      line-height: 17px;
      color: var(--ha-gray);
      mix-blend-mode: normal;
      opacity: 0.8;
    }
  }
}

.workers-selection .item-link.item-content {
  width: 100%;
  align-self: center;
  border-bottom: 1px solid #f9f9f9;
}
.workers-selection .media-list .item-media,
.workers-selection  .item-link .item-inner {
  align-self: center;
}

.searchbar-found{
  height: 100%;
}
.content-location {
  display: block;
  border: 1px solid #e5eef7;
}

.change-location-btn {
  padding: 10px 5px 6px;
}

.item-title-block {
  padding: 15px 20px;
}

.number-icon {
  display: inline-block;
  font-weight: 500;
  font-size: 24px;
  line-height: 42px;
  color: #556080;
  padding: 0 10px 0 10px;
}

.hour {
font-weight: 500;
font-size: 18px;
line-height: 21px;
text-align: right;
color: var(--fr-chocolat);
}

.ic-ok {
  content: url("../../static/img/ic_in_v2.svg");
  width: 100%;
  height: 100%;
}
.ic-out {
  content: url("../../static/img/ic_out_v2.svg");
  width: 100%;
  height: 100%;
}

.date {
  display: block;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #c4c4c4;
}
.icon-status {
  padding: 22px 5px 0;
}
.item-link.item-content {
  padding-left: 0;
}
.smart-border {
  left: 1.67%;
  right: 1.67%;
  top: 14.56%;
  bottom: 81.22%;

  background: #ffffff;
  border: 1px solid #dddddd;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 10px;
  margin-left: 9px;
  width: 97%;
}

.block-title-medium {
      color: #9B9B9B !important;
}
.searchbar-input-wrap{
  border: 1px solid #DDDDDD;
  box-sizing: border-box;
  border-radius: 4px;
}
.searchbar{
  height: 40px;
}
.searchbar-not-found{
  text-align: center;
}

.searchbar-found {
  min-height: calc(90vh - 114px) !important;
  overflow-x: hidden;
  overflow-y: auto;
  background:white;
  border-bottom: 1px solid#ececec;
}
.ios .searchbar-found {
  min-height: calc(90vh - 100px) !important;
}
.searchbar-input-wrap {
  border: 1px solid #dddddd;
  box-sizing: border-box;
  border-radius: 4px;
}
.searchbar {
  border-bottom: 1px solid #d8d8d8;
}
.searchbar-not-found {
  text-align: left;
}
</style>
