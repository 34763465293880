import cordovaApp from '../js/cordova-app';

export default {
  name: 'Nfc',
  initScanner(onTagDetected, onAddListenerSuccess, onAddListenerError) {
    if (!cordovaApp || !cordovaApp.nfc) {
      onAddListenerError('El dispositivo no soporta NFC');
      return;
    }

    cordovaApp.nfc.addTagDiscoveredListener(
      onTagDetected,
      onAddListenerSuccess,
      onAddListenerError,
    );
  },

  stopScanner() {
    if (!cordovaApp || !cordovaApp.nfc) {
      return;
    }

    cordovaApp.nfc.removeTagDiscoveredListener();
  },
};
