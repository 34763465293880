import db from '../../services/Database';

export default {
  getAll() {
    return db.items.toArray();
  },
  getItemsByFamily(family) {
    return db.items.where('family').equals(family).toArray();
  },
  getItemsById(id) {
    return db.items.where('id').equals(id).toArray();
  },
};
