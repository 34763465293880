<template>
  <f7-block
    class="no-padding no-margin"
  >
    <f7-searchbar
      search-container="#manualCheckOutVirtual-list"
      search-item="li"
      search-in=".item-title"
      :placeholder="$t('ManualListSearch')"
    />
    <f7-list
      v-if="vlData.items.length == 0"
      class="nothingfound"
    >
      <f7-list-item
        :title="$t('ManualList_listItem_title')"
      />
    </f7-list>
    <f7-block class="no-padding">
      <f7-list
        id="manualCheckOutVirtual-list"
        class="search-list searchbar-found"
        virtual-list
        :virtual-list-params="vlParams"
      >
        <ul>
          <f7-list-item
            v-for="employee in vlData.items"
            :key="employee.id"
            radio
            name="demo-radio"
            :title="employee.fullname"
            @click="changeStatebtnDisable(employee.object)"
          />
        </ul>
      </f7-list>
      <f7-button
        name="btnContinue"
        class="botonPrimary margin"
        col
        button
        button-large
        button-raised
        fill
        :disabled="btnDisable"
        @click="saveWorker()"
      >
        {{ $t("Nfc_navbarpresence_btnContinue") }}
      </f7-button>
    </f7-block>
    <BottomPanelCheckInOut />
  </f7-block>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import BottomPanelCheckInOut from '../BottomPanelCheckInOut.vue';
import Employees from '../../js/models/Employees';
import TasksSync from '../../js/models/TasksSync';

export default {
  components: {
    BottomPanelCheckInOut,
  },
  props: {
    employees: { type: Array, default: () => [] },
    callbackSelected: { type: Function, default: () => {} },
    fullNameFilter: { type: String, default: '' },
    limitFilter: { type: Number, default: 9999 },
    pageFilter: { type: Number, default: 0 },
  },
  data() {
    return {
      code: '',
      vlData: {
        items: [],
      },
      vlParams: {
        items: this.employees,
        searchAll: this.searchAll,
        renderExternal: this.renderExternal,
        // height: this.$theme.ios ? 63 : (this.$theme.md ? 73 : 46),
      },
      worker: null,
      btnDisable: true,
      pathBack: '/nfcCheckOut/',
    };
  },
  computed: {
    ...mapState('TaskManager', ['id', 'locationId']),
  },
  async beforeMount() {
    this.$f7.preloader.show();
    try {
      const xhrFormat = await Employees.getByFullname(this.fullNameFilter);
      for (let i = 0; i < xhrFormat.length; i += 1) {
        this.employees.push({
          fullname: `${xhrFormat[i].name} ${xhrFormat[i].surname}`,
          code: xhrFormat[i].code,
          object: xhrFormat[i],
        });
      }
      this.vlData.items = this.employees;
    } catch (error) {
      console.debug(error); // TODO: Mostrar este error
    } finally {
      this.$f7.preloader.hide();
    }
  },
  methods: {
    searchAll(query, items) {
      const result = [];
      const lowerQuery = query.toLowerCase();
      items.forEach((item, i) => {
        if (item.fullname.toLowerCase().includes(lowerQuery)) {
          result.push(i);
        }
      });
      return result;
    },
    renderExternal(vl, vlData) {
      this.vlData = vlData;
    },
    saveWorker() {
      this.setSelectedWorker(this.worker);
      this.checkOut('id', this.worker.id);
    },
    changeStatebtnDisable(item) {
      this.btnDisable = false;
      this.worker = item;
    },
    async checkOut(type, code) {
      this.$f7.preloader.show();
      try {
        const check = await TasksSync.checkWorkerInTask(this.id, type, code);
        if (check === null) {
          const event = { type: 'ERROR', status: 'This employee is not working in this task' };
          this.setEvent(event);
          this.$f7.sheet.open('.demo-sheet-swipe-to-step');
        } else if (check.end_date !== null) {
          const event = { type: 'ERROR', status: 'This employee is already checked out' };
          this.setEvent(event);
          this.$f7.sheet.open('.demo-sheet-swipe-to-step');
        } else {
          this.setType(type);
          this.setCode(code);
          this.setSelectedWorker(check);
          this.$f7.views.main.router.navigate('/tasks/checkOut/nfcManuallyCheckOut/', {
            reloadCurrent: true,
          });
        }
      } catch (error) {
        const event = { type: 'ERROR', status: error, error };
        this.code = code;
        this.setEvent(event);
        this.$f7.sheet.open('.demo-sheet-swipe-to-step');
      } finally {
        this.$f7.preloader.hide();
      }
    },
    ...mapActions('TaskManager', ['setWorkers']),
    ...mapActions('worker', ['setSelectedWorker', 'setEvent', 'changeUpSheet', 'setType', 'setCode']),
  },
};
</script>
<style scoped>
.list .item-subtitle {
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: #999;
  mix-blend-mode: normal;
  opacity: 0.8;
}
.searchbar-found {
  min-height: calc(90vh - 125px) !important;
  overflow-x: hidden;
  overflow-y: auto;
  background:white;
  border-bottom: 1px solid#ececec;
}
.ios .searchbar-found {
  min-height: calc(90vh - 125px) !important;
}
.searchbar-input-wrap {
  border: 1px solid #dddddd;
  box-sizing: border-box;
  border-radius: 4px;
}
.searchbar {
  border-bottom: 1px solid #d8d8d8;
}
.searchbar-not-found {
  text-align: left;
}
div.item-content{
  text-align: center !important;
}
</style>
