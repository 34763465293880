import db from '../../services/Database';
import EventBus from '../event-bus';

export default {
  getAll() {
    return db.modifiedRecords.toArray();
  },
  getFilterType(type) {
    return db.modifiedRecords.where('type').equals(type).toArray();
  },
  insert(item) {
    try {
      db.modifiedRecords.put(item);
      EventBus.$emit('updateElementsToSync');
      return true;
    } catch (error) {
      return false;
    }
  },
  async countElement() {
    const countElements = await db.modifiedRecords.count();
    return countElements;
  },
};
