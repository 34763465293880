<template>
  <f7-page
    name="binsConsult"
    class="content-manual-list main-bins-consult"
  >
    <navbar
      :text="`${$t('LeftPanel_Subtitle_ConsultBins')}`"
      :show-button-sync="true"
    />
    <!-- :show-qr-icon="true" -->
    <ManualListBins
      v-if="showManualList"
      :name-code-filter="nameCodeFilter"
    />
    <f7-block
      v-else
      class="no-padding"
    >
      <div class="block block-help">
        <f7-row>
          <div class="item-inner item-cell">
            <div class="item-row">
              <div class="item-cell">
                {{ $t("binsConsult_search_text") }}
              </div>
            </div>
          </div>
        </f7-row>
      </div>
      <f7-list
        inline-labels
        no-hairlines-md
      >
        <f7-list-input
          outline
          type="text"
          name="listInput"
          :placeholder="$t('binsConsult_listInput_placeholder')"
          clear-button
          @input="changeInput($event.target.value)"
        />
      </f7-list>
      <f7-block>
        <f7-button
          name="btnContinue"
          class="botonPrimary color-green"
          col
          button
          button-large
          fill
          :disabled="btnDisable"
          @click="setFilter"
        >
          {{ $t('NfcManually_navbarpresence_btnContinue') }}
        </f7-button>
      </f7-block>
    </f7-block>
  </f7-page>
</template>

<script>
import { mapActions } from 'vuex';
import navbar from '../../components/NavBar.vue';
import ManualListBins from '../../components/nursery/ManualListBins.vue';

export default {
  name: 'BinsConsult',
  components: {
    navbar,
    ManualListBins,
  },

  data() {
    return {
      items: [],
      btnDisable: true,
      vlData: {
        items: [],
      },
      nameCodeFilter: '',
      employees: [],
      showManualList: false,
    };
  },
  computed: {
  },
  async beforeMount() {
    this.syncTasksSync()
      .catch((error) => { this.$f7.dialog.alert(error); });
    await this.fetchLocationTree();
    await this.fetchBinsContentFamilies();
  },
  methods: {
    changeInput(inputValue) {
      this.nameCodeFilter = inputValue;
      this.btnDisable = (this.nameCodeFilter === '');
    },
    setFilter() {
      this.showManualList = true;
    },
    ...mapActions('greenhouse', ['fetchLocationTree', 'fetchBinsContentFamilies']),
    ...mapActions('databaseSync', ['syncTasksSync']),
  },
};
</script>
<style lang="scss">
.content-manual-list {
    background: var(--f7-sheet-bg-color)!important;
}
.content-manual-list .list ul:before,
.content-manual-list .list ul:after{
  display: none!important;
}
.content-manual-list .list ul {
  padding: 0px 0 10px;
}
.content-manual-list .item-input-outline .input-clear-button {
    right: 20px;
}
.content-manual-list .searchbar input[type="search"] {
  background: transparent!important;
}

.md label.item-radio:not(.item-radio-icon-end) > .icon-radio {
  margin-right: 18px;
}
.md label.item-radio:not(.item-radio-icon-start) .item-inner {
  padding-right: 15px;
}
li.list-bins-item .list .item-footer {
  margin-top: 15px!important;
}
</style>
