<template>
  <div class="content-form">
    <f7-block>
      <f7-list no-hairlines-md>
        <f7-row>
          <f7-col
            width="50"
          >
            <div class="content-stepper text-align-center">
              <div class="content-stepper__title">
                {{ $t('Form_machinery_KMInicial') }}
              </div>
              <f7-list-input
                outline
                :disabled="horometer"
                type="number"
                :value="kmStart"
                @input="changeInput($event.target.value)"
              />
            </div>
          </f7-col>
          <f7-col
            width="50"
          >
            <div class="content-stepper text-align-center">
              <div class="content-stepper__title">
                {{ $t('Form_machinery_KMFinal') }}
              </div>
              <f7-list-input
                outline
                :disabled="horometer"
                type="number"
                :value="kmEnd"
                @input="changeInput2($event.target.value)"
              />
            </div>
          </f7-col>
        </f7-row>
        <f7-row>
          <f7-col
            width="50"
          >
            <div class="content-stepper text-align-center">
              <div class="content-stepper__title">
                {{ $t('Form_machinery_horometroInicial') }}
              </div>
              <f7-list-input
                outline
                :disabled="km"
                type="number"
                :value="HoroStart"
                @input="changeInput3($event.target.value)"
              />
            </div>
          </f7-col>
          <f7-col
            :edited="km===false"
            width="50"
          >
            <div class="content-stepper text-align-center">
              <div class="content-stepper__title">
                {{ $t('Form_machinery_horometroFinal') }}
              </div>
              <f7-list-input
                outline
                :disabled="km"
                type="number"
                :value="HoroEnd"
                @input="changeInput4($event.target.value)"
              />
            </div>
          </f7-col>
        </f7-row>
        <f7-row>
          <f7-col
            width="50"
          >
            <div class="content-stepper text-align-center">
              <div class="content-stepper__title">
                {{ $t('Form_machinery_workingArea') }}
              </div>
              <f7-list-input
                outline
                type="number"
                :value="workingArea"
                @input="changeInput7($event.target.value)"
              />
            </div>
          </f7-col>
          <f7-col width="50">
            <div class="content-stepper text-align-center">
              <div class="content-stepper__title">
                {{ $t('Form_machinery_literConsumed') }}
              </div>
              <f7-list-input
                outline
                type="number"
                :value="liters"
                @input="changeInput5($event.target.value)"
              />
            </div>
          </f7-col>
        </f7-row>
        <f7-row>
          <f7-col width="100">
            <f7-list-input
              outline
              :label="`${$t('Form_machinery_observations')}`"
              type="text"
              clear-button
              maxlength="50"
              @input="changeInput6($event.target.value)"
            />
          </f7-col>
        </f7-row>
      </f7-list>
    </f7-block>
    <f7-block class="checkout-productivity no-padding-bottom">
      <f7-row>
        <f7-col
          width="100"
        >
          <div class="item-label item-title margin-left">
            {{ $t('Form_machinery_fuelType') }}
          </div>
          <f7-list
            no-hairlines-md
          >
            <f7-list-item
              :title="`${$t('Form_machinery_fuelType')}`"
              class="typefuel"
              smart-select
              :smart-select-params="{ openIn: 'popup' }"
            >
              <select
                id="fuelType"
                name="fuelType"
                class="input-field"
                style="margin-left: 9px !important;"
                @change="selectFuelType($event.target.value)"
              >
                <option
                  v-for="(item, index) in typefuel"
                  :key="index"
                  :value="item"
                >
                  {{ item }}
                </option>
              </select>
            </f7-list-item>
          </f7-list>
        </f7-col>
      </f7-row>
    </f7-block>
    <f7-block class="content-button no-padding-top">
      <f7-button
        name="btnContinue"
        class="botonPrimary color-green"
        col
        button
        button-large
        button-raised
        fill
        @click="botonContinue"
      >
        {{ $t('NfcManually_navbarpresence_btnContinue') }}
      </f7-button>
    </f7-block>
    <div>
      <f7-row>
        <f7-col
          width="50"
          style="padding: 27px 0px 24px 15px;"
        >
          <f7-button
            v-if="false"
            class="boton-skip-all"
            col
            button
            button-large
            button-raised
            fill
            @click="botonFinish"
          >
            {{ $t('NfcManually_navbarpresence_skipall') }}
          </f7-button>
        </f7-col>
        <f7-col
          width="50"
          style="padding: 27px 15px 24px 0px;"
        >
          <f7-button
            v-if="false"
            class="boton-gotoproduct"
            col
            button
            button-large
            button-raised
            fill
            @click="botonProduct"
          >
            {{ $t('NfcManually_navbarpresence_gotoproduct') }}
          </f7-button>
        </f7-col>
      </f7-row>
    </div>
    <f7-row />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import TasksSync from '../../js/models/TasksSync';

export default {
  data() {
    return {
      kmStart: 0,
      kmEnd: 0,
      HoroStart: 0,
      HoroEnd: 0,
      liters: 0,
      comments: '',
      workingArea: 0,
      fuelType: '',
      machineryPerformance: [],
      hasProduct: false,
      booleanFinish: false,
      km: false,
      horometer: false,
      typefuel: ['', 'DIESEL', 'GASOLINE'],
    };
  },
  computed: {
    ...mapState('TaskManager', ['productivity2', 'id', 'machinery_id', 'items', 'planner', 'machinery_performance', 'items_drivers', 'last_employee', 'observation', 'task_unit', 'checkout_multiple', 'last_employee', 'attachment']),
    ...mapState('worker', ['type', 'code', 'selectedWorker']),
  },
  beforeMount() {
    if (this.items.length > 1) {
      this.booleanFinish = true;
    }
    if (this.attachment.length > 0) {
      this.hasProduct = true;
    }
  },
  methods: {
    async botonContinue() {
      if (this.kmStart > this.kmEnd) {
        return this.$f7.dialog.alert(this.$t('Form_machinery_ERRORKM'));
      }
      if (this.HoroStart > this.HoroEnd) {
        return this.$f7.dialog.alert(this.$t('Form_machinery_ERRORHORO'));
      }
      let ultimo = false;
      this.$f7.preloader.show();
      for (let i = 0; i < this.items.length; i += 1) {
        if (this.items[i].item === this.machinery_id && i < this.items.length - 1) {
          const newMachineryData = {
            item_id: this.machinery_id,
            driver_id: this.items_drivers[i].driver,
            km_start: this.kmStart,
            km_finish: this.kmEnd,
            horometer_start: this.HoroStart,
            horometer_finish: this.HoroEnd,
            fuel_consumption: this.liters,
            comment: this.comments,
            working_area: this.workingArea,
            fuel_type: this.fuelType,
          };
          this.machinery_performance.push(newMachineryData);
          this.setMachineryId(this.items[i + 1].item);
          this.setMachineryPerformance(this.machinery_performance);
          this.$f7.views.main.router.navigate('/tasks/RegistrationMachineryData/', { reloadCurrent: true });
          this.$f7.preloader.hide();
          break;
        } else if (this.items[i].item === this.machinery_id && i === this.items.length - 1) {
          ultimo = true;
          const newMachineryData = {
            item_id: this.machinery_id,
            driver_id: this.items_drivers[i].driver,
            km_start: this.kmStart,
            km_finish: this.kmEnd,
            horometer_start: this.HoroStart,
            horometer_finish: this.HoroEnd,
            fuel_consumption: this.liters,
            comment: this.comments,
            working_area: this.workingArea,
            fuel_type: this.fuelType,
          };
          this.machinery_performance.push(newMachineryData);
          this.setMachineryPerformance(this.machinery_performance);
        }
      }
      if (ultimo === true) {
        await TasksSync.updateMachineryId(this.id, this.machinery_performance);
        if (this.attachment.length > 0) {
          this.$f7.views.main.router.navigate('/tasks/RegistrationFitosanitaryData/', { reloadCurrent: true });
        } else if (this.checkout_multiple) {
          try {
            const response = await TasksSync.checkOutGroup(
              this.id,
              this.task_unit,
              this.productivity2,
              this.observation,
              this.planner.goal_performance.unit,
            );
            this.setEvent({ type: 'OUT', status: 'All workers are out' });
            this.setWorkers(response.workers);
          } catch (error) {
            console.log(error); // TODO: Mostrar info del error
            this.$f7.dialog.alert(error);
          } finally {
            this.$f7.preloader.hide();
          }
        } else {
          try {
            const response = await TasksSync.checkOut(
              this.id,
              this.last_employee,
              this.task_unit,
              this.productivity2,
              this.observation,
              this.planner.goal_performance.unit,
            );
            if (response.error !== undefined) {
              const event = { type: 'ERROR', status: response.text };
              this.setEvent(event);
            } else {
              const name = `${this.selectedWorker.name} ${this.selectedWorker.surname}`;
              this.setEvent({ type: 'OUT', status: name });
              this.setWorkers(response.workers);
            }
          } catch (error) {
            console.log(error); // TODO: Mostrar info del error
          } finally {
            this.$f7.preloader.hide();
          }
        }
        this.$f7.views.main.router.navigate('/tasks/ExportTask/', { reloadCurrent: true });
        this.$f7.preloader.hide();
      }
    },
    changeInput(inputValue) {
      if (inputValue === '') this.kmStart = 0;
      else this.kmStart = inputValue;
      this.km = true;
      if (inputValue === '0' && (this.kmEnd === '0' || this.kmEnd === 0)) this.km = false;
    },
    changeInput2(inputValue) {
      if (inputValue === '') this.kmEnd = 0;
      else this.kmEnd = inputValue;
      this.km = true;
      if (inputValue === '0' && (this.kmStart === '0' || this.kmStart === 0)) this.km = false;
    },
    changeInput3(inputValue) {
      if (inputValue === '') this.HoroStart = 0;
      else this.HoroStart = inputValue;
      this.horometer = true;
      if (inputValue === '0' && (this.HoroEnd === '0' || this.HoroEnd === 0)) this.horometer = false;
    },
    changeInput4(inputValue) {
      if (inputValue === '') this.HoroEnd = 0;
      else this.HoroEnd = inputValue;
      this.horometer = true;
      if (inputValue === '0' && (this.HoroStart === '0' || this.HoroStart === 0)) this.horometer = false;
    },
    changeInput5(inputValue) {
      if (inputValue === '') this.liters = 0;
      else this.liters = inputValue;
    },
    changeInput6(inputValue) {
      this.comments = inputValue;
    },
    changeInput7(inputValue) {
      if (inputValue === '') this.workingArea = 0;
      else this.workingArea = inputValue;
    },
    selectFuelType(e) {
      this.fuelType = e;
    },
    botonProduct() {
      this.$f7.views.main.router.navigate('/tasks/RegistrationFitosanitaryData/', { reloadCurrent: true });
    },
    async botonFinish() {
      this.$f7.preloader.show();
      if (this.checkout_multiple) {
        try {
          const response = await TasksSync.checkOutGroup(
            this.id,
            this.task_unit,
            this.observation,
            this.planner.goal_performance.unit,
          );
          this.setEvent({ type: 'OUT', status: 'All workers are out' });
          this.setWorkers(response.workers);
        } catch (error) {
          console.log(error); // TODO: Mostrar info del error
          this.$f7.dialog.alert(error);
        } finally {
          this.$f7.preloader.hide();
        }
      } else {
        try {
          const response = await TasksSync.checkOut(
            this.id,
            this.last_employee,
            this.task_unit,
            this.observation,
            this.planner.goal_performance.unit,
          );
          if (response.error !== undefined) {
            const event = { type: 'ERROR', status: response.text };
            this.setEvent(event);
          } else {
            const name = `${this.selectedWorker.name} ${this.selectedWorker.surname}`;
            this.setEvent({ type: 'OUT', status: name });
            this.setWorkers(response.workers);
          }
        } catch (error) {
          console.log(error); // TODO: Mostrar info del error
        } finally {
          this.$f7.preloader.hide();
        }
      }
      this.$f7.views.main.router.navigate('/tasks/ExportTask/', { reloadCurrent: false });
    },
    ...mapActions('TaskManager', ['setMachineryId', 'setMachineryPerformance', 'setWorkers']),
    ...mapActions('worker', ['changeUpSheet', 'setEvent', 'changeUpSheet']),
  },
};
</script>

<style lang="scss" scoped>
.boton-skip-all {
  background: #E5EEF7 !important;
  color: #616B7C !important;
  box-shadow: none;
}
.boton-gotoproduct {
  background: #4A90E2 !important;
  color: #FFFFFF !important;
  box-shadow: none;
}
.stepper .stepper-input-wrap input {
  width: 100% !important;
  background: white;
  padding: 0px !important;
}
.list li{
  list-style: none;
}

.checkout-productivity-help::after {
  content: "";
  position: absolute;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-top: 12px solid var(--help-green);
  right: 65px;
  bottom: -11px;
}

.checkout-productivity div.col:nth-child(1) {
  width: 60%;
  align-self: center;
}

.checkout-productivity div.col:nth-child(2) {
  width: 40%;
  align-self: center;
}

.checkout-productivity .user-id {
  display: flex;
  align-items: center;
}

.checkout-productivity .user-id img {
  margin-right: 8px;
}

.checkout-productivity {
  .content-usario {
    align-items: flex-end;
    .item-title {
      color: #556080 !important;
      font-weight: 500;
      font-size: 12px;
    }
  }
}

.ios .stepper .stepper-input-wrap input {
  width: 64px;
}

.content-task-header {
  display: flex;
  flex-direction: row;
  background: var(--f7-sheet-bg-color);
  padding: 10px 15px;
  border-bottom: 1px solid var(--f7-input-outline-border-color);
}

.content-task-header .item-inner:nth-of-type(1) {
  min-width: 75%;
  align-self: center;
}
.content-task-header .item-inner:nth-of-type(2) {
  min-width: 30%;
  align-self: center;
  text-align: center;
}

.content-task-header .item-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 1.2;
  color: #2e2e2e;
  text-transform: capitalize;
  white-space: initial;
  text-align: left;
  display: flex;
  align-items: center;
}

.content-task-header .item-subtitle {
  max-width: 75%;
  text-transform: capitalize;
  font-size: 12px;
  line-height: 1.4;
  margin-top: 3px;
}

.content-task-header .task-meta {
  display: flex;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  margin-top: 8px;
  color: var(--fr-chocolat);
}

.content-task-header .task-meta span {
  display: inline-block;
  margin: 0px 20px 0px 6px;
}

.content-task-header .task-meta img {
  float: left;
}

.content-task-header .button {
  box-shadow: none;
  border: none;
  background-color: transparent;
  border-radius: 0;
  height: 68px;
}

div.input.input-with-value {
  padding-top: 4%;
  padding-left: 8%;
}
div.item-label.smart-title {
  margin-left: 5%;
  margin-right: 5%;
  font-weight: normal;
}
div.input.input-with-value {
  height: 35px;
  margin-left: 5%;
  margin-right: 5%;
  background: #f7f8fa;
  border: 1px solid #d4dce1;
  font-size: 18px;
  border-radius: 8px;
}
div.block-title {
  line-height: 34px;
  text-align: center;
  font-size: 30px;
  color: darkslateblue;
}
.color {
  background: #f7f8fa;
  border: 1px solid #d4dce1;
  border-radius: 8px;
  height: 59px;
  width: 50%;
  left: 16px;
}
.list.item-input-outline.item-floating-label {
  background: #f7f8fa !important;
  width: 70% !important;
}

.header {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
}
.avatar {
  margin-top: 15px;
}
.checkout-productivity-help {
  --help-green: #f0f8eb;
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px;
  margin: 15px 15px 0;
  background: var(--help-green);
  border-radius: 6px;
  line-height: 1.4;
  padding-right: 40px;
  color: #556080;
  filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.13));
}
.checkout-productivity-help.multiple {
  background: #edf5fe;
}

.checkout-productivity-help::after {
  content: "";
  position: absolute;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-top: 12px solid var(--help-green);
  right: 65px;
  bottom: -11px;
}

.checkout-productivity div.col:nth-child(1) {
  width: 60%;
  align-self: center;
}

.checkout-productivity div.col:nth-child(2) {
  width: 40%;
  align-self: center;
}

.checkout-productivity .user-id {
  display: flex;
  align-items: center;
}

.checkout-productivity .user-id img {
  margin-right: 8px;
}

.checkout-productivity {
  .content-usario {
    align-items: flex-end;
    .item-title {
      color: #556080 !important;
      font-weight: 500;
      font-size: 12px;
    }
  }
}

.ios .stepper .stepper-input-wrap input {
  width: 64px;
}

.content-form{
  padding-top: 15px;
  .item-content{
    padding: 0;
    .item-inner{
      padding: 5px 0;
      .input-with-value{
        font-weight: 500;
        font-size: 18px;
      }
    }
  }
  .content-button{
    bottom: 0;
    width: 100%;
    padding-top: 20px;
  }
}
.item-title.item-label {
  margin-right: 15px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: var(--f7-list-item-title-text-color);
  padding-bottom: 2%;
  padding-top: 2%;
}
</style>
