<template>
  <div />
</template>

<script>
import store from '../../store/index';

export default {
  store,
  mounted() {
    store.dispatch('authentication/logout');
    this.$f7.views.main.router.navigate('/accounts/loginPage/', { reloadCurrent: true });
  },
};
</script>
