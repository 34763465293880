<template>
  <f7-page
    name="nfcPage"
    @page:beforein="beforein"
    @page:beforeout="beforeout"
  >
    <navbar
      :elements="elements"
      :text="`${activity} - ${$t('Nfc_navbarpresence_incidence')}`"
      :path-back="pathBack"
    />
    <f7-page-content>
      <f7-block class="text-align-center">
        <f7-icon icon="icon-scan" />
      </f7-block>
      <f7-block name="info">
        <div class="content-block text-align-center">
          <f7-block-title medium>
            {{ $t("Nfc_info_txtTitle") }}
          </f7-block-title>
          <p>{{ $t("Nfc_info_txtSubTitle") }}</p>
        </div>
      </f7-block>
      <f7-block>
        <f7-row>
          <f7-col>
            <div class="check-help">
              <span>{{ $t("assigned") }}
                <b>{{ $t("CheckIn") }}</b></span>
            </div>
          </f7-col>
        </f7-row>
      </f7-block>
      <!-- <f7-block>
        <f7-row>
          <f7-col>
            <f7-button
              name="btnChangePassword"
              class="botonPrimary"
              col
              button
              button-large
              button-raised
              fill
              @click="virtualNFC('011.748.839-K')"
            >
              virtual nfc
            </f7-button>
          </f7-col>
        </f7-row>
      </f7-block> -->
    </f7-page-content>
    <BottomPanelCheckInOut />
  </f7-page>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import navbar from '../../components/NavBar.vue';
// import { f7NavTitleLarge } from 'framework7-vue';
import EventBus from '../../js/event-bus';
import BottomPanelCheckInOut from '../../components/BottomPanelCheckInOut.vue';
import TasksSync from '../../js/models/TasksSync';

export default {
  name: 'NfcPage',

  components: {
    navbar,
    BottomPanelCheckInOut,
  },

  data() {
    return {
      code: '',
      manualAccessControl: false,
      eventAction: (newId) => this.manualAccessAction(newId),
      pathBack: '/tasks/workersSelection/',
      elements: [
        {
          iconEnable: true,
          textEnable: false,
          bagdeEnable: false,
          info: { urlImage: 'icon-new', urlDestiny: '/nfcManually/' },
        },
      ],
    };
  },
  computed: {
    scanDetailsOpened() {
      return this.persona !== null || this.personNotExists === true;
    },
    ...mapState('TaskManager', ['id', 'activity', 'locationId']),
    ...mapState('worker', ['selectedWorker', 'event', 'upSheet']),
    ...mapState('AccessControl', ['currentFarmCode']),
  },
  methods: {
    beforein() {
      this.updateStateSheet();
      this.subscribeNFC();
    },
    beforeout() {
      EventBus.$off('nfc-scan', this.scanNFC);
      this.$f7.sheet.close('.demo-sheet');
    },
    subscribeNFC() {
      EventBus.$on('nfc-scan', this.scanNFC);
    },
    scanNFC(tag) {
      this.checkIn('NFC', tag);
    },
    modalInfoClosed() {
      this.closeModal();
    },
    async checkIn(type, code) {
      this.$f7.preloader.show();
      try {
        const employed = { auth: { type, code } };
        const response = await TasksSync.checkin(
          this.id,
          this.locationId,
          employed,
        );
        if (response.error !== undefined) {
          const event = { type: 'ERROR', status: response.text };
          this.setEvent(event);
          this.$f7.sheet.open('.demo-sheet-swipe-to-step');
        } else if (response === 'checkout') {
          const event = { type: 'ERROR', status: 'This employee is already working' };
          this.setEvent(event);
          this.$f7.sheet.open('.demo-sheet-swipe-to-step');
        } else {
          const aux = response.workers.length;
          const name = `${response.workers[aux - 1].name} ${response.workers[aux - 1].surname}`;
          this.setEvent({ type: 'IN', status: name });
          this.setWorkers(response.workers);
          this.$f7.sheet.open('.demo-sheet-swipe-to-step');
        }
      } catch (error) {
        const event = { type: 'ERROR', status: error, error };
        this.code = code;
        this.setEvent(event);
        this.$f7.sheet.open('.demo-sheet-swipe-to-step');
      } finally {
        this.$f7.preloader.hide();
      }
    },

    virtualNFC(tag) {
      this.checkIn('NFC', tag);
    },
    updateStateSheet() {
      if (this.upSheet === true) {
        this.$f7.sheet.open('.demo-sheet-swipe-to-step');
        this.changeUpSheet();
      }
    },
    ...mapActions('TaskManager', ['setWorkers', 'changeUpSheet']),
    ...mapActions('worker', [
      'changeUpSheet',
      'setSelectedWorker',
      'setEvent',
      'setType',
      'setCode',
    ]),
  },
};
</script>
<style lang="scss" scoped>
.page-content {
  color: #9b9b9b !important;

  .block-title-medium {
    color: #9b9b9b !important;
  }
}
div.demo-sheet.sheet-modal.sheet-modal-bottom.modal-in {
  height: 40%;
}
.check-help {
  --help-green: #F0F8EB;
  position: relative;
  display: flex;
  align-items: center;
  padding: 20px;
  margin: 15px 0;
  background: rgba(234, 227, 216, 0.7);;
  border-radius: 6px;
  font-size: 14px;
  line-height: 18px;
  color: #6F4343;
  filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.13));
}
.check-help::after {
    content: "";
    position: absolute;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid rgba(234, 227, 216, 0.7);;
    right: 65px;
    top: -10px;
}
</style>
