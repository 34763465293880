<template>
  <f7-block
    class="no-padding no-margin"
  >
    <f7-searchbar
      search-container="#manualVirtual-list-bins"
      search-item="li"
      search-in=".item-title"
      :placeholder="$t('binsConsult_search_text')"
    />
    <f7-list
      v-if="vlData.items.length == 0"
      class="nothingfound"
    >
      <f7-list-item
        :title="$t('ManualListBins_listItem_title')"
      />
    </f7-list>
    <f7-block class="no-padding">
      <f7-list
        id="manualVirtual-list-bins"
        class="search-list searchbar-found bg"
        virtual-list
        :virtual-list-params="vlParams"
      >
        <ul>
          <f7-list-item
            v-for="element in vlData.items"
            :key="element.id"
            class="list-bins-item"
            radio
            name="demo-radio"
            :title="element.name"
            :header="element.code"
            :footer="element.location"
            @click="changeStatebtnDisable(element)"
          >
            <div
              class="item-after"
              :class="element.status.toLowerCase().trim()"
            >
              {{ getStatusText(element.status) }}
            </div>
          </f7-list-item>
        </ul>
      </f7-list>
      <f7-button
        name="btnContinue"
        class="margin"
        col
        button
        button-large
        button-raised
        fill
        :disabled="btnDisable"
        @click="navigateToBinContent()"
      >
        {{ $t("Nfc_navbarpresence_btnContinue") }}
      </f7-button>
    </f7-block>
    <BottomPanelCheckInOut />
  </f7-block>
</template>

<script>
import { mapActions } from 'vuex';
import moment from 'moment';
import BottomPanelCheckInOut from '../BottomPanelCheckInOut.vue';
import Location from '../../js/models/Locations';
import Bins from '../../js/models/Bins';
import EventBus from '../../js/event-bus';

export default {
  name: 'ManualListBins',
  components: {
    BottomPanelCheckInOut,
  },
  props: {
    callbackSelected: { type: Function, default: () => {} },
    nameCodeFilter: { type: String, default: '' },
    limitFilter: { type: Number, default: 9999 },
    pageFilter: { type: Number, default: 0 },
    bins: { type: Array, default: () => [] },
  },
  data() {
    return {
      code: '',
      vlData: {
        items: [],
      },
      vlParams: {
        items: this.bins,
        searchAll: this.searchAll,
        renderExternal: this.renderExternal,
      },
      bin: null,
      btnDisable: true,
    };
  },
  computed: {
  },
  async beforeMount() {
    this.updateList();
    EventBus.$on('refresh-taskslist', this.updateList);
  },
  beforeDestroy() {
    EventBus.$off('refresh-taskslist');
  },
  methods: {
    async updateList() {
      this.$f7.preloader.show();
      try {
        this.vlData = { items: [] };
        this.bins.length = 0;
        const xhrFormat = await Bins.getByNameOrCode(this.nameCodeFilter);
        for (let i = 0; i < xhrFormat.length; i += 1) {
          const locationName = await Location.getLocationById(
            xhrFormat[i].shipments[xhrFormat[i].shipments.length - 1].origin,
          );
          const lastShipment = { ...xhrFormat[i].shipments[xhrFormat[i].shipments.length - 1] };
          const lastContent = { ...lastShipment.content[lastShipment.content.length - 1] };

          const newElements = [];
          for (const element of lastContent.elements) {
            const newElement = {
              category: element.category,
              class_type: element.class_type,
              code: element.code,
              count: element.count,
              location: element.location,
              movements: element.movements,
              parent: element.parent,
              variety: element.variety,
            };
            newElements.push(newElement);
          }

          const newContent = { date: moment(new Date()).format('YYYY-MM-DD'), elements: newElements };
          this.bins.push({
            code: xhrFormat[i].code,
            company: xhrFormat[i].company,
            date: xhrFormat[i].date,
            id: xhrFormat[i].id,
            lote: xhrFormat[i].lote,
            max_index: xhrFormat[i].max_index,
            name: xhrFormat[i].name,
            register_date: xhrFormat[i].register_date,
            shipments: xhrFormat[i].shipments,
            status: lastShipment.status,
            location: locationName,
            lastContentBin: newContent,
          });
        }
        this.vlData.items = this.bins;
      } catch (error) {
        console.debug(error); // TODO: Mostrar este error
      } finally {
        this.$f7.preloader.hide();
      }
    },
    searchAll(query, items) {
      const result = [];
      const lowerQuery = query.toLowerCase();
      items.forEach((item, i) => {
        if (item.name.toLowerCase().includes(lowerQuery)
        || item.code.toLowerCase().includes(lowerQuery)) {
          result.push(i);
        }
      });
      return result;
    },
    renderExternal(vl, vlData) {
      this.vlData = vlData;
    },
    navigateToBinContent() {
      this.setSelectedBin(this.bin);
      this.setContentBin(this.bin.lastContentBin);
      this.$f7.views.main.router.navigate('/bins/content/', { reloadCurrent: true });
    },
    changeStatebtnDisable(item) {
      this.btnDisable = false;
      this.bin = item;
    },
    getStatusText(status) {
      this.statusTranslated = '';
      switch (status) {
        // CREATED REGISTERED SENT TO_RECEIVE RECEIVED CLOSED
        case 'CREATED':
          this.statusTranslated = this.$t('binContent_State_Created');
          break;
        case 'REGISTERED':
          this.statusTranslated = this.$t('binContent_State_Registered');
          break;
        case 'SENT':
          this.statusTranslated = this.$t('binContent_State_Sent');
          break;
        case 'TO_RECEIVE':
          this.statusTranslated = this.$t('binContent_State_ToReceive');
          break;
        case 'RECEIVED':
          this.statusTranslated = this.$t('binContent_State_Received');
          break;
        case 'CLOSED':
          this.statusTranslated = this.$t('binContent_State_Closed');
          break;
        default:
          break;
      }
      return this.statusTranslated;
    },
    ...mapActions('greenhouse', ['setSelectedBin', 'setContentBin']),
  },
};
</script>
<style lang="scss">
.main-bins-consult{
  .list-bins-item{
    .item-inner{
      .item-footer{
        margin-top: 10px;
      }
      .item-after{
        color: #FFFFFF;
        padding: 3px 16px;
        border-radius: 2px;
        font-size: 10px;
        font-weight: 500;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 60px;
        &.created{
          background-color: #D4DCE1;
          color: #434E6F;
        }
        &.registered{
          background-color: #556080;
        }
        &.sent{
          background-color: #F5A623;
        }
        &.to_receive{
          background-color: #4A98F7;
        }
        &.received{
          background-color: #388E3C;
        }
        &.closed{
          background-color: #FF654F;
        }
      }
    }
  }
  li.list-bins-item {
      border: 1px solid #ececec;
      background: white;
      margin: 8px;
      border-radius: 3px;
      display: flex;
      flex-direction: column;
  }

  .searchbar-found {
    min-height: calc(90vh - 125px) !important;
    overflow-x: hidden;
    overflow-y: auto;
    border-bottom: 1px solid#ececec;
  }
  .ios .searchbar-found {
    min-height: calc(90vh - 100px) !important;
  }
  .searchbar-input-wrap {
    background: white;
    box-sizing: border-box;
    border-radius: 0px;
  }
  .searchbar {
    border-bottom: 1px solid #d8d8d8;
  }
  .searchbar-not-found {
    text-align: left;
  }
}
</style>
