import Api from './Api';
import db from './Database';
import EventBus from '../js/event-bus';
import Bins from '../js/models/Bins';

export default {
  // TODO: Revisar el parámetro que le llega.
  downloadNewData() {
    return Promise.all([
      Api.getAllRecords('sync/tasks/definition'),
      Api.getAllRecords('sync/tasks'),
      Api.getAllRecords('sync/employees'),
      Api.getAllRecords('sync/locations/tree'),
      Api.getAllRecords('sync/tasks/activities'),
      Api.getAllRecords('sync/keyvalues'),
      Api.getAllRecords('sync/items?type=MACHINERY'),
      // Api.getAllRecords('sync/items?type=PRODCUT'),
      Api.getAllRecords('sync/bins'),
      Api.getAllRecords('nursery/variety'),
      Api.getAllRecords('nursery/category'),
      Api.getAllRecords('/nursery/classtype'),
    ])
      .then(async (values) => {
        if (values[0] !== 0) {
          db.tasksDefinition.bulkPut(values[0]);
        }
        if (values[1] !== 0) {
          db.tasksSync.clear();
          db.tasksSync.bulkPut(values[1]);
        }
        if (values[2] !== 0) {
          db.employees.bulkPut(values[2]);
        }
        if (values[3] !== 0) {
          db.locations.bulkPut(values[3]);
        }
        if (values[4] !== 0) {
          db.tasksActivities.bulkPut(values[4]);
        }
        if (values[5] !== 0) {
          db.families.bulkPut(values[5]);
        }
        if (values[6] !== 0) {
          db.items.bulkPut(values[6]);
        }
        if (values[7] !== 0) {
          db.bins.bulkPut(values[7]);
        }
        if (values[8] !== 0) {
          db.binsVariety.bulkPut(values[8]);
        }
        if (values[9] !== 0) {
          db.binsCategory.bulkPut(values[9]);
        }
        if (values[9] !== 0) {
          db.binsClassification.bulkPut(values[10]);
        }
        return values;
      });
  },

  downloadTasksSync() {
    return Promise.all([
      Api.getAllRecords('sync/tasks'),
      Api.getAllRecords('bins'),
    ])
      .then(async (values) => {
        if (values[0] !== 0) {
          db.tasksSync.clear();
          db.bins.clear();
          db.tasksSync.bulkPut(values[0]);
          db.bins.bulkPut(values[1]);
        }
      });
  },

  clearDatabase() {
    db.employees.clear();
    db.families.clear();
    db.items.clear();
    db.locations.clear();
    db.modifiedRecords.clear();
    db.tasksActivities.clear();
    db.tasksDefinition.clear();
    db.tasksSync.clear();
    db.bins.clear();
    db.binsVariety.clear();
    db.binsCategory.clear();
    db.binsClassification.clear();
  },

  async uploadPendingData() {
    const deleted = await db.modifiedRecords.where({ type: 'taskDeleted' }).toArray();
    for (let i = 0; i < deleted.length; i += 1) {
      Api.deleteTask(deleted[i].idTask);
    }
    const filterTasks = await db.tasksSync.toArray();
    const responseTasks = await Api.sendChangesRecords('sync/tasks', filterTasks);
    const filterBins = await Bins.getModifiedBins();
    const responseBins = await Api.sendChangesRecords('sync/bins', filterBins);
    if (responseTasks === 204 && responseBins === 204) {
      return 204;
    }
    return 'Error de uploadPendingData';
  },

  async syncWithUpstream() {
    const countElementsToUpload = await db.modifiedRecords.count();
    if (countElementsToUpload === 0) {
      return this.downloadNewData().catch(
        (e) => {
          console.log('Error DEL FRONT:', e);
          return [0];
        },
      ).finally(() => EventBus.$emit('refresh-taskslist', true));
    }
    try {
      const response = await this.uploadPendingData();
      if (response === 204) {
        db.modifiedRecords.clear();
        this.downloadNewData().finally(() => EventBus.$emit('refresh-taskslist', true));
      }
    } catch (e) {
      console.log('Error DEL FRONT:', e);
    }
    return [0];
  },

  async syncTasksSync() {
    const countElementsToUpload = await db.modifiedRecords.count();
    if (countElementsToUpload === 0) {
      return this.downloadTasksSync().finally(() => EventBus.$emit('refresh-taskslist', true));
    }
    try {
      const response = await this.uploadPendingData();
      if (response === 204) {
        db.modifiedRecords.clear();
        this.downloadTasksSync().finally(() => EventBus.$emit('refresh-taskslist', true));
      }
    } catch (e) {
      console.log('Error DEL FRONT:', e);
    }
    return countElementsToUpload;
  },
};
