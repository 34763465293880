<template>
  <f7-page
    class="content-list-order main-binsmove  bg-cream"
  >
    <navbar
      :new-icon-enable="true"
      :text="`${selectedBin.code}`"
      :path-back="`${pathBack}`"
    />
    <div class="container">
      <f7-list
        v-if="loaded"
      >
        <!-- General item -->
        <div class="block strong bg-color-chocolat text-color-white">
          <f7-row>
            <f7-col>
              <div class="content-location-site">
                <img
                  class="icon-line"
                  width="16"
                  height="16"
                  :src="images.location"
                >
                <span
                  v-if="locationDestination"
                  class="location-site"
                >
                  {{ selectedBin.location }} → {{ locationDestination }}
                </span>
                <span v-else>
                  {{ selectedBin.location }}
                </span>
              </div>
            </f7-col>
          </f7-row>
          <f7-row>
            <f7-col>
              <div class="content-quanty">
                <div class="quanty-item">
                  <small>{{ $t("binContent_NBins") }}</small>
                  <p>{{ selectedBin.name }}</p>
                </div>
                <div class="quanty-item">
                  <small>{{ $t("binContent_NBatch") }}</small>
                  <p>{{ getNameLote(selectedBin.lote) }}</p>
                </div>
                <div class="quanty-item">
                  <small>{{ $t("binContent_State") }}</small>
                  <a class="button bg-color-blue button-small button-fill">
                    {{ getStatusText(selectedBin.status) }}
                  </a>
                </div>
              </div>
            </f7-col>
          </f7-row>
        </div>
        <!-- End of General item -->

        <!-- Location item -->
        <f7-list-item
          checkbox
          :title="`${$t('binsMove_CheckBox_SelectAll')}`"
          name="demo-checkbox"
          @change="selectAll"
        />
        <f7-list
          ref="list_bins"
          media-list
        >
          <f7-list-item
            v-for="(element, index) in contentBin.elements"
            :key="index"
            checkbox
            class="bg-color-white"
            @change="onBinsContentSelectedChange(element.code, element.location)"
          >
            <div class="item-top">
              {{ element.code }}
            </div>
            <div class="item-title-row padding-top-x2">
              <div class="item-title">
                {{ getVarietyNameByCode(element.variety) }}
              </div>
              <div class="item-after">
                {{ getCategoryNameByCode(element.category) }}
              </div>
            </div>
            <div class="item-subtitle">
              {{ getNameLocationById(element.location) }}
            </div>
            <div class="item-text">
              <f7-row>
                <f7-col class="no-padding-bottom padding-top">
                  Class {{ element.class_type }}
                </f7-col>
                <f7-col class="text-align-right no-padding-bottom">
                  <div class="number-plant padding-top-half">
                    {{ element.count }}
                    <img
                      class="icon-line margin-left-half"
                      width="20"
                      height="20"
                      :src="images.leaf"
                    >
                  </div>
                </f7-col>
              </f7-row>
            </div>
          </f7-list-item>
        </f7-list>

        <f7-button
          name="btnContinue"
          class="botonPrimary color-green margin"
          button
          button-large
          fill
          @click="moveSelectedContent()"
        >
          {{ $t("BinsContent_Button_btnContinue") }} <!-- TODO Cambiar por Save -->
        </f7-button>
      </f7-list>
    </div>
  </f7-page>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import navbar from '../../components/NavBar.vue';

import ImageLeaf from '../../static/img/ic_leaf_circle.svg';
import ImageLocation from '../../static/img/ic_place.svg';

export default {
  name: 'BinsMove',
  components: {
    navbar,
  },
  props: {
    pathBack: { type: String, default: '/bins/content/' },
    // value: { type: Boolean, default: false },
  },
  data() {
    return {
      loaded: true,
      statusTranslated: '',
      binsLocationsSelected: [],
      binsIdsSelected: [],
      isAllChecked: false,
      images: {
        leaf: ImageLeaf,
        location: ImageLocation,
      },
      continueButtonPressed: false,
    };
  },
  computed: {
    ...mapGetters('greenhouse', ['selectedLocations', 'formattedTree', 'selectedBin', 'locationDestination', 'contentBin', 'binsContentVarieties', 'binsContentCategories']),
  },
  beforeDestroy() {
    if (this.contentBin.modified === true && this.continueButtonPressed === false) {
      const newBin = this.selectedBin;
      newBin.modified = true;
      const lastShipment = newBin.shipments.length - 1;
      const lastContent = newBin.shipments[lastShipment].content.length - 1;
      newBin.shipments[newBin.shipments.length - 1].content[lastContent] = this.contentBin;
      this.setSelectedBin(newBin);
    }
  },
  methods: {
    // changeVal() {
    //   this.$emit('input', !this.value);
    // },
    selectAll() {
      if (!this.isAllChecked) {
        this.contentBin.elements.forEach((element) => {
          const index = this.binsIdsSelected.indexOf(element.code);
          if (index === -1) {
            this.binsIdsSelected.push(element.code);
            this.binsLocationsSelected.push(element.location);
          }
        });
        this.isAllChecked = true;
      } else {
        this.binsLocationsSelected = [];
        this.binsIdsSelected = [];
        this.isAllChecked = false;
      }
      // this.changeVal();
      // Forzamos activar o desactivar todos los checkbox
      this.$refs.list_bins.$children.forEach((element) => {
        element.checked = this.isAllChecked;
      });
    },
    moveSelectedContent() {
      if (this.binsLocationsSelected.length > 0) {
        this.setIdsBinsToMove(this.binsIdsSelected);
        this.setSelectedLocations(this.binsLocationsSelected);
        this.continueButtonPressed = true;
        this.$f7.views.main.router.navigate('/bins/changeLocation/', { reloadCurrent: true });
      } else {
        this.$f7.dialog.alert(this.$t('binsMove_Alert_ButtonMove'));
      }
    },
    onBinsContentSelectedChange(id, location) {
      const index = this.binsIdsSelected.indexOf(id);
      if (index >= 0) {
        this.binsIdsSelected.splice(index, 1);
        this.binsLocationsSelected.splice(index, 1);
      } else {
        this.binsIdsSelected.push(id);
        this.binsLocationsSelected.push(location);
      }
    },
    getNameLote(lote) {
      if (lote) {
        return lote.name;
      }
      return '';
    },
    getNameLocationById(idLocation) {
      let nameLocation = '';
      const filteredElements = this.formattedTree.filter((element) => element.bd_id === idLocation);
      if (filteredElements.length > 0) {
        nameLocation = filteredElements[0].name;
      }
      return nameLocation;
    },
    getVarietyNameByCode(codeVariety) {
      let name = '';
      const filteredElements = this.binsContentVarieties.filter(
        (element) => element.code === codeVariety,
      );
      if (filteredElements.length > 0) {
        name = filteredElements[0].name;
      }
      return name;
    },
    getCategoryNameByCode(codeCategory) {
      let name = '';
      const filteredElements = this.binsContentCategories.filter(
        (element) => element.code === codeCategory,
      );
      if (filteredElements.length > 0) {
        name = filteredElements[0].name;
      }
      return name;
    },
    getStatusText(status) {
      this.statusTranslated = '';
      switch (status) {
        // CREATED REGISTERED SENT TO_RECEIVE RECEIVED CLOSED
        case 'CREATED':
          this.statusTranslated = this.$t('binContent_State_Created');
          break;
        case 'REGISTERED':
          this.statusTranslated = this.$t('binContent_State_Registered');
          break;
        case 'SENT':
          this.statusTranslated = this.$t('binContent_State_Sent');
          break;
        case 'TO_RECEIVE':
          this.statusTranslated = this.$t('binContent_State_ToReceive');
          break;
        case 'RECEIVED':
          this.statusTranslated = this.$t('binContent_State_Received');
          break;
        case 'CLOSED':
          this.statusTranslated = this.$t('binContent_State_Closed');
          break;
        default:
          break;
      }
      return this.statusTranslated;
    },
    ...mapActions('greenhouse', ['setContentBin', 'setSelectedBin', 'setSelectedLocations', 'setIdsBinsToMove']),
  },
};

</script>

<style lang="scss">
.main-binsmove{
  .list{
    .media-list{
      max-height: calc(85vh - 200px) !important;
      overflow-x: hidden;
      overflow-y: auto;
      border-bottom: 1px solid #ececec;
    }
  }
}
.content-list-order{
  .enfasis {
    font-weight: 500;
      font-size: 16px;
      line-height: 1.2;
      color: var(--fr-kinder);
  }
  .block-header {
    background: var(--fr-chocolat);
    justify-content: space-between;
  }
  .block-header .item-inner {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
  }

  .block-header.header-space {
    padding: 25px;
  }

  .block-header .number-icon {
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    display: inline-block;
    padding: 5px 0px 5px 5px;
    color: whitesmoke;
    letter-spacing: -0.07em;
  }

  .botonWorkerSelect {
    display: flex;
    width: 100%;
    padding-left: 10px;
    padding-right: 15px;
    text-transform: capitalize;
  }

  .list-order-box {
    position: relative;
    padding: 15px 20px;
    background: var(--f7-sheet-bg-color);
    margin-top: -6px;
    z-index: 0;
  }

  .list-order-box .row .col {
    padding: 10px 0;
    word-break: break-all;
  }

  .list-order-box .row .col:nth-last-of-type(2) {
    max-width: 120px;
    line-height: 1.2;
    color: var(--fr-nutella);
  }

  .list-order-box .row .col:nth-last-of-type(1) {
    font-weight: 500;
    font-size: 16px;
    line-height: 1.2;
    color:var(--fr-kinder);
    width: 50%;
  }
  .list-startorder-box .row .col:nth-last-of-type(2) {
    width: 40%;
    line-height: 1.2;
    color: var(--f7-list-item-subtitle-text-color);
  }

  .list-startorder-box .row .col:nth-last-of-type(1) {
    font-weight: 500;
    font-size: 16px;
    line-height: 1.2;
    color:var(--fr-kinder);
    width: 50%;
  }
  .accordion-off {
    background: var(--fr-avellana)!important;
  }

  .botonWorkerSelect img {
    margin-right: 5px;
  }

  .triangle {
    border-top: 6px solid var(--f7-theme-color-shade);
  }
  .background-shadow {
    background-color:rgba(123, 0, 0, 0.1);
    border-radius: 5px;
  }
  .star-order-list{
    &__location{
      .item-after{
        .badge{
          right: 50px;
          padding: 10px 15px;
          left: initial!important;
        }
      }
      .accordion-item-content{
        .list-order-box{
          padding: 15px 10px!important;
          div{
            &::before, &::after{
              height: 0;
            }
          }
        }
      }
      .location-item{
        font-size: 15px;
        color: var(--f7-theme-color);
        background: var(--f7-searchbar-bg-color);
        border-radius: var(--f7-badge-size);
        display: inline-flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        padding: 5px 12px;
        font-weight: 500;
      }
    }
  }
}
.number-plant {
  font-size: 20px;
  line-height: 1.2;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.main-binsmove label.item-checkbox > .icon-checkbox {
  margin-right: 15px!important;
}

.item-top {
    display: flex;
    color: var(--f7-kinder) !important;
    font-weight: 400;
    line-height: 1;
    border-bottom: 1px solid #eee;
    justify-content: flex-start;
    font-size: 13px;
    letter-spacing: -0.02em;
    position: absolute;
    background: #f9f9f9;
    width: calc(100% + 50px);
    left: -50px;
    top: 0;
    padding: 5px 16px;
    border-top: 1px solid #eee;
}
.padding-top-x2 {
    padding-top: 20px;
    line-height: 1;
    margin-top: 5px;
}
</style>
