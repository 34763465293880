<template>
  <f7-panel
    left
    cover
    resizable
    :opened="showToDefault"
  >
    <div class="content-menu-header">
      <div class="menu-header-item">
        <img
          class="image_logo"
          :src="APP_LOGO"
        >
      </div>
    </div>
    <f7-list media-list>
      <ul class="Contenedorf7list">
        <f7-list-group
          v-for="(item, index) in elements"
          :key="index"
          @click="navigateTo(item.url)"
        >
          <f7-list-item
            v-if="item.name!=''"
            :title="$t(item.name)"
            group-title
          />

          <f7-list-item
            v-for="(itemMenu, index2) in item.menus"
            :key="index2"
            class="panel-close close"
            @click="navigateTo(itemMenu.url)"
          >
            <f7-icon
              v-if="itemMenu.image!=''"
              slot="media"
              :class="`icon-menu ${itemMenu.icon}`"
              width="32"
              height="32"
              @click="navigateTo(itemMenu.url)"
            />
            <a>{{ $t(itemMenu.name) }}</a>
            <br>
          </f7-list-item>
        </f7-list-group>
      </ul>
    </f7-list>
    <div class="toolbar_panel">
      <f7-chip
        media-bg-color="blue"
      >{{ user.first_name}} {{user.last_name}}
        <f7-icon
          slot="media"
          ios="f7:person"
          aurora="f7:person"
          md="material:person"
        />
      </f7-chip>
    </div>
  </f7-panel>
</template>

<script>
import {
  mapState, mapActions,
} from 'vuex';
import { APPLICATION_NAME, APP_LOGO } from '../js/constants';
import store from '../store/index';

export default {
  name: 'LeftPanel',
  store,
  props: {
    elements: { type: Array, default: () => [] },
    showToDefault: { type: Boolean, default: false },
  },
  data() {
    return {
      APPLICATION_NAME,
      APP_LOGO,
    };
  },
  computed: {
    appName() {
      return this.APPLICATION_NAME;
    },
    appLogo() {
      return localStorage.getItem('applogo') || this.APP_LOGO;
    },
    ...mapState('authentication', ['user']),
  },
  methods: {
    navigateTo(path) {
      if (path === '/accounts/loginPage/') {
        // store.dispatch('authentication/logout');
        this.logoutPressed();
      } else {
        this.$f7.views.main.router.navigate(path, { reloadCurrent: true });
      }
    },
    logoutPressed() {
      const self = this;
      this.$f7.dialog.confirm(
        `${this.$t('loggOut_Message')}`,
        () => {
          self.$f7.panel.close('left', false);
          self.logout();
        },
      );
    },
    ...mapActions('authentication', ['logout']),
  },
};
</script>

<style lang="scss">
.toolbar_panel {
  bottom: 0px !important;
  position: absolute;
  padding: 10px;
}
.image_logo {
  width: 90%;
  height: auto;
  margin: 10px 15px;
}

.list ul ul{
  padding-left: 0px;
}
.content-menu-header {

      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-direction: normal;
      -moz-box-direction: normal;
      -webkit-box-orient: horizontal;
      -moz-box-orient: horizontal;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-box-pack: start;
      -moz-box-pack: start;
      -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -webkit-align-content: stretch;
      -ms-flex-line-pack: stretch;
      align-content: stretch;
      -webkit-box-align: start;
      -moz-box-align: start;
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start;
      padding-right: 10px;
      }

    .content-menu-header .menu-header-item:nth-child(1) {
      -webkit-box-ordinal-group: 1;
      -moz-box-ordinal-group: 1;
      -webkit-order: 0;
      -ms-flex-order: 0;
      order: 0;
      -webkit-box-flex: 1;
      -moz-box-flex: 1;
      -webkit-flex: 1 1 64px;
      -ms-flex: 1 1 64px;
      flex: 1 1 64px;
      -webkit-align-self: center;
      -ms-flex-item-align: center;
      align-self: center;
      }

    .content-menu-header .item-inner a{
      color: black;
    }
    .content-menu-header .menu-header-item:nth-child(2) {
      -webkit-box-ordinal-group: 1;
      -moz-box-ordinal-group: 1;
      -webkit-order: 0;
      -ms-flex-order: 0;
      order: 0;
      -webkit-box-flex: 1;
      -moz-box-flex: 1;
      -webkit-flex: 1 1 80%;
      -ms-flex: 1 1 80%;
      flex: 1 1 80%;
      -webkit-align-self: center;
      -ms-flex-item-align: center;
      align-self: center;
      }

  @-moz-document url-prefix() {
  .content-menu-header {
      width: 100%;
      -moz-box-sizing: border-box;

      }

  }
  .content-menu .list ul {
    background: white;
  }
  .content-menu .list-group-title {
    border-bottom: 1px solid #ececec;
  }
  .content-menu .page-content {
    background: #F7F8FA;
    border-right: 1px solid var(--f7-list-border-color);
  }
  //TODO: Ver porqué se ha alinea a la derecha | Alinear nombre del menú a la izquierda
  .Contenedorf7list .item-inner{
    display: flex!important;
    align-self: center;
    align-items: center;
  }
  .item-media {
    margin-left: 0 !important;
  }

  .Contenedorf7list .icon-menu {
    width: 32px;
    height:32px;
  }

  .icon-home {
    background-image: url('../static/img/icon_inicio.svg');
  }

  .icon-dailysummary {
    background-image: url('../static/img/ic_dailysummary.svg');
  }

  .icon-searchworkers {
    background-image: url('../static/img/ic_searchworkers.svg');
  }

  .icon-consultbins {
    background-image: url('../static/img/ic_consultbins.svg');
  }

  .icon-historyboards {
    background-image: url('../static/img/ic_historyboards.svg');
  }

  .icon-setting {
    background-image: url('../static/img/ic_setting.svg');
  }

  .icon-logout {
    background-image: url('../static/img/ic_singout.svg');
  }
</style>
