<template>
  <f7-page
    class="content-list-order main-changelocation"
  >
    <navbar
      :new-icon-enable="true"
      :text="`${$t('binsChangeLocation_Title')}`"
      :path-back="`${pathBack}`"
    />
    <f7-block class="main-changelocation__title">
      <div>
        {{ `${selectedBin.code}` }}
      </div>
    </f7-block>
    <f7-block class="main-changelocation__subtitle">
      <div>
        {{ `${$t('binsChangeLocation_Advice_SelectLastLevel')}` }}
      </div>
    </f7-block>
    <f7-block class="no-padding-horizontal main-changelocation__treelist">
      <f7-treeview>
        <RecursiveTreeView
          :nodes="locationTreeTreated"
          :previous-selected-locations="selectedLocations"
          :single-selection-enabled="true"
        />
      </f7-treeview>
    </f7-block>

    <f7-button
      name="btnSave"
      class="botonPrimary color-green margin"
      button
      button-large
      fill
      @click="saveChanges()"
    >
      {{ $t("BinsContent_Button_btnSave") }}
    </f7-button>
  </f7-page>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import navbar from '../../components/NavBar.vue';
import RecursiveTreeView from '../../components/nursery/RecursiveTreeView.vue';

export default {
  name: 'BinsChangeLocation',
  components: {
    navbar,
    RecursiveTreeView,
  },
  props: {
    pathBack: { type: String, default: '/bins/move/' },
  },
  data() {
    return {
      loaded: false,
      previousLocation: [],
    };
  },
  computed: {
    ...mapGetters('greenhouse', ['selectedLocations', 'locationTreeTreated', 'contentBin', 'idsBinsToMove', 'selectedBin']),
  },
  async beforeMount() {
    this.previousLocation = this.selectedLocations;
    this.loaded = true;
  },
  beforeDestroy() {
    this.setSelectedLocations([]);
    this.setParentLocationsSelected([]);
  },
  methods: {
    saveChanges() {
      if (this.selectedLocations.length !== 1) {
        this.$f7.dialog.alert(this.$t('binsChangeLocation_Alert_SelectOneLocation'));
      } else if (this.previousLocation.length !== 1
          || this.previousLocation[0] !== this.selectedLocations[0]) {
        const newLocation = this.selectedLocations[0];
        this.idsBinsToMove.forEach((element) => {
          const elementToMove = this.contentBin.elements.filter((item) => item.code === element)[0];
          const newMovement = { location: this.selectedLocations[0], date: moment(new Date()).format('YYYY-MM-DD') };
          elementToMove.movements.push(newMovement);
          elementToMove.location = newLocation;
        });
        this.contentBin.modified = true;
      }
      this.$f7.views.main.router.navigate('/bins/move/', { reloadCurrent: true });
    },
    ...mapActions('greenhouse', ['setSelectedLocations', 'setParentLocationsSelected']),
  },
};

</script>

<style lang="scss">
.main-changelocation{
  &__title{
    background-color: var(--fr-chocolat);
    color: white;
    font-size: 16px;
    padding: 10px 20px;
    z-index: 2;
    &::after{
      content: "";
      position: absolute;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid var(--fr-chocolat);
      left: 15px;
      top: 40px;
      z-index: 1;
    }
  }
  &__subtitle{
    z-index: 1;
    background-color: var(--fr-inprogress-tint);
    padding: 10px 20px;
    color: var(--fr-chocolat);
    font-weight: 500;
    font-size: 14px;
    border-bottom: 1px solid #D8D8D8;
  }
  &__treelist{
    max-height: calc(85vh - 130px) !important;
    overflow-x: hidden;
    overflow-y: auto;
    border-bottom: 1px solid #ececec;
  }
  .list {
    ul{
      &::before{
        height: 0!important;
      }
      .item-content{
        padding-left: 0;
        min-height: 25px;
        .icon-checkbox{
          margin-right: 10px!important;
        }
        .item-inner{
          padding-top: 4px;
          padding-bottom: 4px;
          min-height: 25px;
        }
      }
    }
  }
}
.triangle {
  border-top: 6px solid var(--f7-theme-color-shade);
}
</style>
