import {
  f7,
} from 'framework7-vue';
import Request from './Request';
import EventBus from '../js/event-bus';

let username = '';
let token = '';
let headerAuth = {};

const agriFarm = 'chile';
const baseUrl = 'https://fmis.demo.hispatecanalytics.com'; // Endpoint estable desarrollo
// const baseUrl = 'https://mobile.development.hispatecanalytics.com'; // Endpoint beta
// const baseUrl = 'https://api.pre.hcoagriplatform.com'; // Endpoint preproducción
// const baseUrl = 'https://api.hcoagriplatform.com'; // Endpoint producción

EventBus.$on('newLoggedUser', (payload) => {
  const data = JSON.parse(payload);
  username = data.username;
  token = data.token;
  headerAuth = `Bearer ${token}`;
});

export default {

  errors: {
    default: {
      name: 'error',
      message: 'error',
      callback() {},
    },

    E002: {
      name: 'invalid_token',
      message: 'Su sesión ha expirado',

      callback() {
        if (!username) return;
        username = null;
        EventBus.$emit('invalidTokenDetected');
        f7.dialog.alert(this.message);
      },
    },
  },
  getToken() {
    return token;
  },
  getHeaderAuth() {
    return headerAuth;
  },
  getAgriFarm() {
    return agriFarm;
  },
  getBaseUrl() {
    return baseUrl;
  },
  login(credentials) {
    const url = `${baseUrl}/api/user/authorization`;
    const payload = {
      data: JSON.stringify({
        username: credentials.username,
        password: credentials.password,
      }),
    };

    return Request.async(url, payload, 'POST', false, 'application/json')
      .then(this.handleLoginResult);
  },

  async handleLoginResult(result) {
    if (result.response !== '') {
      EventBus.$emit('newLoggedUser', result.response);
    }
  },

  recoveryPassword(email, urlClient) {
    const url = `${baseUrl}/user/password_reset/`;
    const payload = {
      data: JSON.stringify({
        url: urlClient,
        email,
      }),
    };
    return Request.async(url, payload, 'POST', false, 'application/json');
  },

  // TODO: Pendiente de eliminar
  updateTask(taskId, location, items) {
    const url = `${baseUrl}/${agriFarm}/tasks/${taskId}`;
    const payload = {
      data: JSON.stringify({
        location,
        items,
      }),
    };
    return Request.async(url, payload, 'POST', false, 'application/json', headerAuth);
  },

  // Daily Plan
  getDailyTasks(all = false, date = '', dateInit = '', dateEnd = '') {
    let url = `${baseUrl}/${agriFarm}/tasks/daily?all=${all}`;
    if (date !== '') {
      url += `&date=${date}`;
    }
    if (dateInit !== '') {
      url += `&date_init=${dateInit}`;
    }
    if (dateEnd !== '') {
      url += `&date=${dateEnd}`;
    }
    const payload = {
      data: JSON.stringify({
        username: this.username,
      }),
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  
  async sendChangesRecords(endpoint, items) {
    const url = `${baseUrl}/${agriFarm}/${endpoint}`;
    const payload = {
      data: JSON.stringify(
        items,
      ),
    };
    // TODO: Controlar paginacion. Enviar petición de X elementos / total elementos.
    const response = await this.request(url, payload, 'PUT', false, 'application/json', headerAuth);
    return response;
  },

  async getAllRecords(endpoint, numElementos = 0) {
    let url = `${baseUrl}/${agriFarm}/${endpoint}`;
    if (numElementos > 0) {
      url += `${endpoint.indexOf('?') > 0 ? '&' : '?'}limit=${numElementos}&`;
    }
    const payload = {
      data: null,
    };

    return this.request(url, payload, 'GET', false, 'application/json', headerAuth);
  },

  //  Auxiliary methods
  async request(url, payload, verb, processData, contentType) {
    let responseServer = null;
    let xhr = await Request.async(url, payload, verb, processData, contentType, headerAuth);
    try {
      responseServer = JSON.parse(xhr.response);
      if (responseServer.paginator !== undefined) {
        const totalPage = (responseServer.paginator)
          ? (responseServer.paginator).total_page : 0;
        if (typeof totalPage !== 'undefined' && totalPage > 1) {
          for (let i = 2; i <= totalPage; i += 1) {
            const updatedUrl = `${url}?page=${i}`;
            // eslint-disable-next-line no-await-in-loop
            xhr = await Request.async(updatedUrl, payload, verb,
              processData, contentType, headerAuth);
            responseServer.data.push(...JSON.parse(xhr.response).data);
          }
        }
        responseServer = responseServer.data;
      } else {
        responseServer = (responseServer.data) ? responseServer.data : responseServer;
      }
    } catch (error) {
      responseServer = xhr.status;
    }
    return responseServer;
  },

  getException(errorcode) {
    return errorcode in this.errors ? this.errors[errorcode] : this.errors.default;
  },

  deleteTask(taskId) {
    const url = `${baseUrl}/${agriFarm}/tasks/${taskId}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'DELETE', false, 'application/json', headerAuth);
  },
};
