<template>
  <f7-page
    name="nfcManuallyCheckOut"
    class="content-manual-list"
  >
    <navbar
      :new-icon-enable="false"
      :path-back="pathBack"
      :text="`${activity} - ${$t('Nfc_navbarpresence_incidence_checkout')}`"
    />
    <ManualListCheckOut
      v-if="showManualList"
      :full-name-filter="fullNameFilter"
    />
    <f7-block
      v-else
      class="no-padding"
    >
      <div class="block block-help">
        <f7-row>
          <div class="item-inner item-cell">
            <div class="item-row">
              <div class="item-cell">
                {{$t("nfcManuallySearch")}}
              </div>
            </div>
          </div>
        </f7-row>
      </div>
      <f7-list
        inline-labels
        no-hairlines-md
      >
        <f7-list-input
          outline
          type="text"
          name="listInput"
          :placeholder="$t('NfcManually_listInput_placeholder')"
          clear-button
          @input="changeInput($event.target.value)"
        />
      </f7-list>
      <f7-block>
        <f7-button
          name="btnContinue"
          class="botonPrimary"
          col
          button
          button-large
          button-raised
          fill
          :disabled="btnDisable"
          @click="setFullNameFilter"
        >
          {{ $t('NfcManually_navbarpresence_btnContinue') }}
        </f7-button>
      </f7-block>
      <f7-block>
        <f7-row>
          <f7-col>
            <div class="check-help">
              <span>{{ $t("assigned") }}
                <b>{{ $t("CheckOut") }}</b></span>
            </div>
          </f7-col>
        </f7-row>
      </f7-block>
    </f7-block>
  </f7-page>
</template>

<script>
import { mapState } from 'vuex';
import navbar from '../../components/NavBar.vue';
import ManualListCheckOut from '../../components/presence/ManualListCheckOut.vue';

export default {
  name: 'NfcManuallyCheckOut',
  components: {
    navbar,
    ManualListCheckOut,
  },

  data() {
    return {
      items: [],
      btnDisable: true,
      vlData: {
        items: [],
      },
      fullNameFilter: '',
      employees: [],
      showManualList: false,
      pathBack: '/nfcCheckOut/',
    };
  },
  computed: {
    ...mapState('AccessControl', ['currentFarmCode']),
    ...mapState('TaskManager', ['activity']),
  },
  methods: {
    changeInput(inputValue) {
      this.fullNameFilter = inputValue;
      this.btnDisable = (this.fullNameFilter === '');
    },
    setFullNameFilter() {
      this.showManualList = true;
    },
  },
};
</script>
<style lang="scss">
.content-manual-list {
    background: var(--f7-sheet-bg-color);
}
.content-manual-list .list ul:before,
.content-manual-list .list ul:after{
  display: none!important;
}
.content-manual-list .list ul {
  padding: 0px 0 10px;
}
.content-manual-list .item-input-outline .input-clear-button {
    right: 20px;
}
.content-manual-list .searchbar input[type="search"] {
  background: transparent!important;
}
.check-help {
  --help-green: #F0F8EB;
  position: relative;
  display: flex;
  align-items: center;
  padding: 20px;
  margin: 15px 0;
  background: rgba(234, 227, 216, 0.7);;
  border-radius: 6px;
  font-size: 14px;
  line-height: 18px;
  color: #6F4343;
  filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.13));
}
.check-help::after {
    content: "";
    position: absolute;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid rgba(234, 227, 216, 0.7);;
    right: 65px;
    top: -10px;
}
</style>
