<template>
  <div>
    <f7-link
      v-if="textEnable"
      @click="navigateTo(info.urlDestiny)"
    >
      {{ info.text }}
    </f7-link>
    <f7-link
      v-if="iconEnable"
      @click="navigateTo(info.urlDestiny)"
    >
      <f7-icon
        :class="`icon-menu ${info.urlImage}`"
      >
        <f7-badge
          v-if="bagdeEnable"
        >
          {{ info.notificationsNumber }}
        </f7-badge>
      </f7-icon>
    </f7-link>
  </div>
</template>

<script>

export default {
  name: 'ExtraElement',
  props: {
    textEnable: { type: Boolean, default: false },
    iconEnable: { type: Boolean, default: false },
    bagdeEnable: { type: Boolean, default: false },
    info: { type: Object, default: null },
  },
  methods: {
    navigateTo(path) {
      this.$f7.views.main.router.navigate(path, { reloadCurrent: true });
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-nfc {
  background-image: url('../static/img/ic_nfc.svg');
  width: 44px;
  height: 44px;
}
.ios .icon-nfc { width: 38px; height: 38px;}
.icon-export {
  background-image: url('../static/img/icon_export.svg');
  width: 44px;
  height: 44px;
}
.ios .icon-export { width: 38px; height: 38px;}
.icon-new {
  background-image: url('../static/img/ic_new.svg');
  width: 44px;
  height: 44px;
  background-size: inherit;
  background-position: center top;
}
.ios .icon-new { width: 38px; height: 38px; background-size: 45px; background-position: left top;} 
</style>