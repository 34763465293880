import db from '../../services/Database';
import ModifiedRecords from './ModifiedRecords';

export default {
  getAll() {
    return db.bins.toArray();
  },
  async getByNameOrCode(nameOrCode = '') {
    let bins = [];
    if (nameOrCode !== '') {
      bins = await db.bins.filter(
        (element) => (element.code.toUpperCase().includes(nameOrCode.toUpperCase())
        || element.name.toUpperCase().includes(nameOrCode.toUpperCase()))
        && element.shipments[element.shipments.length - 1].status !== 'CLOSED'
        && element.shipments[element.shipments.length - 1].status !== 'CREATED',
      ).toArray();
    }
    return bins;
  },
  async updateBin(bin) {
    db.bins.where({ id: bin.id })
      .modify({ shipments: bin.shipments, modified: true })
      .then(() => { });

    ModifiedRecords.insert({ type: 'bin', idTask: bin.id, idLocation: bin.name });
  },
  async updateFullBin(bin) { // bin already have modified: true
    await db.bins.where({ id: bin.id }).modify((value, ref) => {
      ref.value = bin;
    });
    ModifiedRecords.insert({ type: 'bin', idTask: bin.id, idLocation: bin.name });
  },
  async getModifiedBins() {
    const bins = await db.bins.filter(
      (element) => element.modified && element.modified === true,
    ).toArray();
    return bins;
  },
};
