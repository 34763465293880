<template>
  <div>
    <f7-accordion-content>
      <span
        v-for="(item, index) in resumeTask"
        :key="index"
      >
        <f7-chip
          v-if="item.type == 'COMPLETED'"
          overflow-hiden
          :text="item.type"
          media-bg-color="green"
          :media="item.count"
          @click="filterByStatus($event,item.type)"
        >
          <f7-icon
            slot="media"
            ios="f7:plus_circle"
            aurora="f7:plus_circle"
          />
        </f7-chip>
        <f7-chip
          v-else-if="item.type == 'IN_PROGRESS'"
          :key="index"
          overflow-hiden
          :text="item.type"
          media-bg-color="orange"
          :media="item.count"
          @click="filterByStatus($event,item.type)"
        >
          <f7-icon
            slot="media"
            ios="f7:plus_circle"
            aurora="f7:plus_circle"
          />
        </f7-chip>
        <f7-chip
          v-else
          :key="index"
          overflow-hiden
          :text="item.type"
          media-bg-color="red"
          :media="item.count"
          @click="filterByStatus($event,item.type)"
        >
          <f7-icon
            slot="media"
            ios="f7:plus_circle"
            aurora="f7:plus_circle"
          />
        </f7-chip>
      </span>
    </f7-accordion-content>
  </div>
</template>

<script>

import planDaily from '../../json/planDaily.json';

export default {
  name: 'AccordionFilterTask',
  props: {
    tasklist: { type: Array, default: () => [] },
    resumeTask: { type: Array, default: () => [] },
    currentTasks: { type: Array, default: () => [] },
    completedTasks: { type: Array, default: () => [] },
  },
  data() {
    return {
      statusList: ['Open', 'In progress', 'Finished'],
      planDaily,
    };
  },
  methods: {
    filterByStatus(e, status) {
      const { $$ } = this;
      const app = this.$f7;
      const { target } = e;
      let color = $$(target).parent('.chip').css('background-color');
      if (status === 'Open' || status === 'Pending') {
        if (color !== 'rgba(0, 0, 0, 0.12)') {
          color = 'rgba(0, 0, 0, 0.12)';
        } else {
          color = 'lightgreen';
        }
      } else if (status === 'In progress') {
        if (color !== 'rgba(0, 0, 0, 0.12)') {
          color = 'rgba(0, 0, 0, 0.12)';
        } else {
          color = 'orange';
        }
      } else if (status === 'Finished') {
        if (color !== 'rgba(0, 0, 0, 0.12)') {
          color = 'rgba(0, 0, 0, 0.12)';
        } else {
          color = 'red';
        }
      }
      $$(target).parent('.chip').css('background-color', color);
    },
  },
};
</script>

<style>
div.accordion-item-content{
  background-color: white;
}
</style>
