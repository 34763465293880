<template>
  <f7-sheet
    class="demo-sheet content-nfc"
    data-swipe-to-close="true"
  >
    <f7-page-content>
      <div class="content-nfc-sheet">
        <div class="sheet-info">
          <div
            v-if="event.type==='ERROR'"
            class="event-type-icon"
          />
          <div v-else>
            <img
              class="ic-user margin-right"
            >
          </div>
          <div class="item-title-row">
            <div
              v-if="event.type==='ERROR' && event.status===404"
              class="item-title-bold"
            >
              {{ $t("Nfc_into_error_404") }} : {{ tag }}
            </div>
            <div
              v-if="event.type==='ERROR' && event.status===404"
              class="item-title-bold"
            >
              {{ $t("Nfc_into_error_404") }} : {{ tag }}
            </div>
            <div
              v-else-if="event.type==='ERROR' && event.status===400"
              class="item-title-bold"
            >
              {{ $t("Nfc_into_error_400") }} : {{ tag }}
            </div>
            <div
              v-else-if="event.type==='ERROR' && event.status===406"
              class="item-title-bold"
            >
              {{ $t("Nfc_into_error_406") }}
            </div>
            <div
              v-else-if="event.type==='ERROR' && event.status===409"
              class="item-title-bold"
            >
              {{ $t("Nfc_into_error_409") }}
            </div>
            <div
              v-else-if="event.type==='ERROR' && event.error!==''"
              class="item-title-bold"
            >
              {{ event.error }}
            </div>
            <div
              v-else
              class="item-title-bold"
            >
              {{ selectedWorker.name }} {{ selectedWorker.surname }}
            </div>
            <!--<div class="item-subtitle">
            </div>-->
          </div>
        </div>
        <div class="sheet-status">
          <img
            v-if="event!=null && event.type==='ERROR'"
            class="ic-err"
          >
          <img
            v-else-if="event!=null && event.type==='IN'"
            class="ic-ok"
          >
          <img
            v-else
            class="ic-out"
          >
        </div>
      </div>
    </f7-page-content>
  </f7-sheet>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'SheetNFC',
  props: { tag: { type: String, default: '' } },
  data() {
    return {
    };
  },

  computed: {
    ...mapState('TaskManager', ['workers']),
    ...mapState('worker', ['selectedWorker', 'event']),
  },
};
</script>

<style lang="scss" scoped>
.demo-sheet.sheet-modal.sheet-modal-bottom.modal-in {
  height: auto !important;
}
.page-content {
  color: #9b9b9b !important;

  .block-title-medium {
    color: #9b9b9b !important;
  }
}

.content-nfc-sheet {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    overflow: hidden;
}

.content-nfc-sheet .item-title-bold {
  color: var(--ha-blue);
}

.content-nfc-sheet .sheet-info {
    flex-shrink: 2;
    padding:  0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sheet-status {
    margin-bottom: -5px;
}

.ic-ok {
  content: url("../static/img/ic_ok.svg");
  height: 100px;
  width: 96px;
}

.ic-out {
  content: url("../static/img/ic_out.svg");
  height: 100px;
}

.ic-err {
 content: url("../static/img/incidencia.svg");
  height: 100px;
}

.ic-user {
  content: url("../static/img/ic_user.svg");
  width: 58px;
  height: 58px;
}
</style>
