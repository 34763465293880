<template>
  <f7-page
    name="workersSelection"
    class="workers-selection"
    @page:beforein="beforein"
  >
    <navbar
      :text="activity"
      :elements="elements"
      :list="items"
      :type-export="'workersSelection'"
      :path-back="pathBack"
    /> <!-- :title="locationName" Pintar location en title del navbar -->
    <div>
      <div class="content-task-header">
        <div class="item-inner">
          <div class="item-title-row">
            <div class="item-title text-color-black no-margin no-padding">
              {{ task_name }}
            </div>
            <div class="item-subtitle text-color-chocolat">
              {{ getLocations(addLocation) }} ⇢ <strong class="text-color-nutella">{{ activity }}</strong>
            </div>
            <div class="item-after" />
          </div>
          <div class="item-subtitle">
            <div class="task-meta">
              <img
                width="20"
                height="20"
                :src="images.ic_worker"
              >
              <span>{{ countIn(workers) }}/{{ count(plan_workers) }}</span>
              <img
                width="20"
                height="20"
                :src="images.ic_timetask"
              >
              <span>{{ timeOfWorked(workers) }}</span>
            </div>
          </div>
        </div>
        <div class="item-inner">
          <f7-button
            @click="$f7.sheet.open('#bottomPanelButons')"
          >
            <img
              width="64"
              height="64"
              :src="images.ic_stop"
            >
          </f7-button>
          <span class="text-color-red">{{$t("onlyStop")}}</span>
        </div>
      </div>
    </div>
    <searcher v-if="workers.length !== 0" />
    <div v-if="workers.length === 0">
      <f7-block class="text-align-center">
        <f7-icon icon="icon-scan" />
      </f7-block>
      <f7-block name="info">
        <div class="content-block text-align-center">
          <f7-block-title medium>
            {{ $t("TasksList_WorkersSelection_title") }}
          </f7-block-title>
          <p>{{ $t("TasksList_WorkersSelection_subtitle") }}</p>
        </div>
      </f7-block>
    </div>
    <SheetExport
      :propssheetexport="propsSheetExport"
    />
    <ButtomPanel :elements="buttomPanelConfig" />
  </f7-page>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import moment from 'moment';
import Searcher from '../../components/presence/Searcher.vue';
import navbar from '../../components/NavBar.vue';
import ImageEdit from '../../static/img/ic_edit-btn-round.svg';
import ImageUser from '../../static/img/ic_user.svg';
import IconScan from '../../static/img/icon-scan.svg';
import IconQr from '../../static/img/ic_qr.svg';
import SheetExport from '../../components/SheetExport.vue';
import EventBus from '../../js/event-bus';
import ButtomPanel from '../../components/BottomPanel.vue';
import ImageStop from '../../static/img/ic_stop_task.svg';
import ImageTime from '../../static/img/ic_time_task.svg';
import ImageUserTask from '../../static/img/ic_user_task.svg';
import Locations from '../../js/models/Locations';

export default {
  components: {
    navbar,
    Searcher,
    SheetExport,
    ButtomPanel,
  },
  props: {
    type: { type: String, default: '' },
    fullName: { type: String, default: '' },
    timeStamp: { type: Number, default: Number },
    startDate: { type: [Number, String], default: Number },
    date: { type: Number, default: Number },
    avatar: { type: String, default: '' },
  },
  data() {
    const items = [];
    const total = 0;
    const buttomPanelConfig = [
      {
        class: 'icon-individual',
        name: this.$t('workersSelection_buttomPanelConfig_individual'),
        id: 'individual',
        link: '/nfcCheckOut/',
        title: `${this.$t('workersSelection_buttomPanelConfig_title')}:`,
      },
      {
        class: 'icon-multiple',
        name: this.$t('workersSelection_buttomPanelConfig_multiple'),
        id: 'multiple',
        link: '/tasks/checkOutMulti/', // TODO: Cambiar el link para la página de checkout multiple
        title: `${this.$t('workersSelection_buttomPanelConfig_title')}:`,
      }];
    return {
      navbar,
      buttomPanelConfig,
      elements: [
        {
          iconEnable: true,
          textEnable: false,
          bagdeEnable: false,
          info: { urlImage: 'icon-nfc', urlDestiny: '/nfc/' },
        },
      ],
      sheetOpened: false,
      wantsToChangeLocation: false,
      iconPresence: '../static/icons/presence.svg',
      imgError: false,
      items,
      total,
      vlData: {
        items: [],
      },
      images: {
        edit: ImageEdit,
        user: ImageUser,
        IconScan,
        IconQr,
        ic_stop: ImageStop,
        ic_timetask: ImageTime,
        ic_worker: ImageUserTask,
      },
      location: [],
      finalLevelLocation: [],
      availableLocations: [],
      locationSelectedCode: null,
      propsSheetExport: {},
      pathBack: '/tasks/list/',
      addLocation: [],
    };
  },
  computed: {
    ...mapState('AccessControl', ['currentLocationCode', 'currentFarmCode']),
    ...mapState('authentication', ['loggedUser']),
    ...mapState('TaskManager', ['workers', 'task_code', 'task_name', 'activity', 'activityId', 'locationName', 'farm', 'plan_workers', 'showRegisterProductivity', 'locations', 'locationId']),
  },
  methods: {
    beforein() {
      this.updateList();
      if (this.showRegisterProductivity) {
        this.changeShowRegisterProductivity();
        this.$f7.sheet.open('#bottomPanelButons');
      }
      EventBus.$on('exportCSV', this.openSheetExport);
    },
    async updateList() {
      this.$f7.preloader.show();
      this.locationsTree = await Locations.getAll();
      this.getFinalLevelLocation(this.locations);
      const xhrFormat = this.workers;
      for (let i = 0; i < xhrFormat.length; i += 1) {
        let event;
        if (xhrFormat[i].end_date !== null) {
          event = { date: xhrFormat[i].end_date, type: 'OUT' };
        } else {
          event = { date: xhrFormat[i].init_date, type: 'IN' };
        }
        this.items.push({
          fullname: `${xhrFormat[i].name} ${xhrFormat[i].surname}`,
          badge_id: `${xhrFormat[i].badge_id}`,
          name: `${xhrFormat[i].name}`,
          surname: `${xhrFormat[i].surname}`,
          activity_id: this.activityId,
          activity_name: this.activity,
          id: xhrFormat[i].id,
          init_date: xhrFormat[i].init_date,
          event,
          end_date: xhrFormat[i].end_date,
          task_name: this.task_name,
          task_id: this.task_code,
          location: this.finalLevelLocation,
          driver: `${xhrFormat[i].driver}`,
          performance: `${xhrFormat[i].performance}`,
          unit: `${xhrFormat[i].unit}`,
        });
      }
      for (let i = 0; i < this.locationId.length; i += 1) {
        this.checkRestChild(this.locationId[i], this.locationsTree);
      }
      this.$f7.preloader.hide();
      this.vlData.items = [];
      this.vlData.items = this.items;
      EventBus.$emit('load-workers', this.items);
    },
    checkRestChild(item, locationsTree) {
      for (let i = 0; i < locationsTree.length; i += 1) {
        if (locationsTree[i].children.length > 0) {
          for (let j = 0; j < locationsTree[i].children.length; j += 1) {
            if (locationsTree[i].children[j].children.length > 0) {
              for (let k = 0; k < locationsTree[i].children[j].children.length; k += 1) {
                if (locationsTree[i].children[j].children[k].children.length > 0) {
                  for (let l = 0; l < locationsTree[i].children[j].children[k].children.length; l += 1) {
                    if (locationsTree[i].children[j].children[k].children[l].children.length > 0) {
                      for (let m = 0; m < locationsTree[i].children[j].children[k].children[l].children.length; m += 1) {
                        if (locationsTree[i].children[j].children[k].children[l].children[m].bd_id === item) {
                          this.addLocation.push(locationsTree[i].children[j].children[k].name);
                          for (let n = 0; n < this.addLocation.length - 1; n += 1) {
                            if (this.addLocation[n] === this.addLocation[n + 1]) {
                              this.addLocation.splice(n, 1);
                            }
                          }
                        }
                      }
                    } else if (locationsTree[i].children[j].children[k].children[l].bd_id === item) {
                      this.addLocation.push(locationsTree[i].children[j].children[k].name);
                      for (let n = 0; n < this.addLocation.length - 1; n += 1) {
                        if (this.addLocation[n] === this.addLocation[n + 1]) {
                          this.addLocation.splice(n + 1, 1);
                        }
                      }
                    }
                  }
                } else if (locationsTree[i].children[j].children[k].bd_id === item) {
                  this.addLocation.push(locationsTree[i].children[j].children[k].name);
                  for (let n = 0; n < this.addLocation.length - 1; n += 1) {
                    if (this.addLocation[n] === this.addLocation[n + 1]) {
                      this.addLocation.splice(n + 1, 1);
                    }
                  }
                }
              }
            } else if (locationsTree[i].children[j].bd_id === item) {
              this.addLocation.push(locationsTree[i].children[j].name);
              for (let n = 0; n < this.addLocation.length - 1; n += 1) {
                if (this.addLocation[n] === this.addLocation[n + 1]) {
                  this.addLocation.splice(n + 1, 1);
                }
              }
            }
          }
        } else if (locationsTree[i].bd_id === item) {
          this.addLocation.push(locationsTree[i].name);
          for (let n = 0; n < this.addLocation.length - 1; n += 1) {
            if (this.addLocation[n] === this.addLocation[n + 1]) {
              this.addLocation.splice(n, 1);
            }
          }
        }
      }
      this.addLocation = this.addLocation.filter(this.onlyUnique);
    },
    onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    },
    getLocations(item) {
      let aux = '';
      if (item.length > 0) {
        for (let i = 0; i < item.length; i += 1) {
          if (i === 0) aux += `${item[i]}`;
          else aux += `, ${item[i]}`;
        }
      }
      return aux;
    },
    count(item) {
      let x = 0;
      for (let i = 0; i < item.length; i += 1) {
        x += item[i].count;
      }
      return x;
    },
    countIn(item) {
      let x = 0;
      for (let i = 0; i < item.length; i += 1) {
        if (item[i].end_date === null) {
          x += 1;
        }
      }
      return x;
    },
    getProperty(properties, property) {
      for (let i = 0; i < properties.length; i += 1) {
        if (properties[i].key === property) {
          return properties[i].value;
        }
      }
      return '';
    },
    openSheetExport(propsSheetExport) {
      this.propsSheetExport = propsSheetExport;
      this.$f7.sheet.open('.demo-sheet-swipe-to-close');
    },
    goToPresence() {
      const self = this;
      const router = self.$f7router;
      router.navigate('/nfc/', { reloadCurrent: true });
    },
    setImgError() {
      this.imgError = true;
    },
    searchAll(query, items) {
      const found = [];
      for (let i = 0; i < items.length; i += 1) {
        if (
          items[i].fullname.toLowerCase().indexOf(query.toLowerCase()) >= 0
          || query.trim() === ''
        ) found.push(i);
      }
      return found; // return array with mathced indexes
    },
    renderExternal(vl, vlData) {
      this.vlData = vlData;
    },
    async refreshAvailableLocations() {
      this.$f7.preloader.show();
      // const allowedLocations = await db.allowedLocations.toArray();
      // const allowedIds = allowedLocations.map((location) => location.id);
      // const locations = this.location;
      // this.availableLocations = locations.filter((location) => location.type === 'Garita');
      this.$f7.preloader.hide();
      this.wantsToChangeLocation = false;
    },
    timeOfWorked(workers) {
      let totalTime = 0;
      for (let i = 0; i < workers.length; i += 1) {
        const checkinDate = moment(workers[i].init_date);
        if (workers[i].end_date !== null) {
          const checkoutDate = moment(workers[i].end_date);
          const duration = moment.utc((moment.duration(checkoutDate.diff(checkinDate)).asMilliseconds()));
          if (totalTime < duration) {
            totalTime = duration;
          }
        } else {
          let checkoutDate = moment(new Date());
          checkoutDate = checkoutDate.format('YYYY-MM-DD HH:mm:ss');
          checkoutDate = moment.utc(checkoutDate);
          const duration = moment.utc((moment.duration(checkoutDate.diff(checkinDate)).asMilliseconds()));
          if (totalTime < duration) {
            totalTime = duration;
          }
        }
      }
      return moment.utc(totalTime).format('HH:mm');
    },
    getFinalLevelLocation(locations) {
      this.finalLevelLocation = [];
      for (const item of this.locationsTree) {
        this.viewChildren(item, locations);
      }
      return this.finalLevelLocation;
    },
    viewChildren(item, locations) {
      for (let i = 0; i < item.children.length; i += 1) {
        this.viewChildren(item.children[i], locations);
      }
      if (item.children.length === 0) {
        if (locations.find((element) => element === item.bd_id)) {
          this.finalLevelLocation.push(item.name);
        }
      }
    },
    ...mapActions('AccessControl', ['selectLocation', 'scan']),
    ...mapActions('TaskManager', ['changeShowRegisterProductivity']),
  },
};
</script>

<style scoped>
.content-task-header {
  display: flex;
  flex-direction: row;
  background: var(--f7-sheet-bg-color);
  padding: 10px 15px;
  border-bottom: 1px solid var(--f7-input-outline-border-color);
}

.content-task-header .item-inner:nth-of-type(1) {
  min-width: 75%;
  align-self: center;
}
.content-task-header .item-inner:nth-of-type(2) {
  min-width: 30%;
  align-self: center;
  text-align: center;
}

.content-task-header .item-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 1.2;
  color: #2E2E2E;
  text-transform: capitalize;
  white-space: initial;
  text-align: left;
  display: flex;
  align-items: center;
}

.content-task-header .item-subtitle {
  max-width: 80%;
  text-transform: capitalize;
  font-size: 12px;
  line-height: 1.2;
  margin-top: 3px;
}

.content-task-header .task-meta {
  display: flex;
  font-weight: 500;
  font-size: 18px;
  line-height: 1.2;
  margin-top: 5px;
  color: var(--fr-chocolat);
}

.content-task-header .task-meta span {
  display: inline-block;
  margin: 0px 20px 0px 6px;
}

.content-task-header .task-meta img {
  float: left;
}

.content-task-header .button {
  box-shadow: none;
  border: none;
  background-color: transparent;
  border-radius: 0;
  height: 68px;
}

.workers-selection .item-inner {
  padding: 0;
}

.content-location {
  display: block;
  border: 1px solid #e5eef7;
}

.change-location-btn {
  padding: 10px 5px 6px;
}

.item-title-block {
  padding: 15px 20px;
}

.content-location {
  display: block;
  border: 1px solid #e5eef7;
}

.header-space {
  position: relative;
  padding: 20px 35px;
}
.number-icon {
  display: inline-block;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 42px;
  color: #556080;
  padding: 0 10px 0 10px;
}
.list .item-title {
  padding: 10px 0 5px;
  margin-right: 10px;
  font-size: 18px;
  letter-spacing: -0.04em;
}
.item-title-row {
  align-items: center;
}
.list .item-subtitle {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #9b9b9b;
  padding: 2px 0px 0px;
}
.hour {
  font-weight: 500;
  font-size: 26px;
  letter-spacing: -0.04em;
  line-height: 1.2;
  color: #556080;
  margin: 2px;
}

.date {
  display: block;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #c4c4c4;
}
.icon-status {
  padding: 22px 5px 0;
}
.item-link.item-content {
  padding-left: 0;
}
.smart-border {

  background: #ffffff;
  border: 1px solid #dddddd;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 10px;
  margin-left: 9px;
  width: 97%;
}

a .i.icon.material-icon{
  color: #ffffff;
}
div.item-inner.infoUser::after{
  display: none;
}
.virtual-list ul{
  height: auto !important;
}
</style>
