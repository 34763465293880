<template>
  <f7-sheet
    class="demo-sheet-swipe-to-step"
    style="height:auto; --f7-sheet-bg-color: #fff;"
    swipe-to-close
    backdrop
    @sheet:closed="onPageBeforeOut()"
  >
    <f7-page-content>
      <div class="row">
        <div class="col-25">
          <img
            class="ic-user"
          >
        </div>
        <div
          class="col-50 texto-trabajador"
        >
          {{ event.status }}
        </div>
        <div class="col-25">
          <div
            v-if="event.type==='ERROR'"
            style="display: flex;"
          >
            <img
              class="ic-incidence"
            >
          </div>
          <div
            v-if="event.type==='IN'"
            style="display: flex;"
          >
            <img
              class="ic-ok"
            >
          </div>
          <div
            v-if="event.type==='OUT'"
            style="display: flex;"
          >
            <img
              class="ic-out"
            >
          </div>
        </div>
      </div>
    </f7-page-content>
  </f7-sheet>
</template>
<script>
import { mapState } from 'vuex';

export default {
  name: 'BottomPanelCheckInOut',
  data() {
    return {
    };
  },
  computed: {
    ...mapState('worker', ['event']),
  },
  methods: {
    onPageBeforeOut() {
      const self = this;
      // Close opened sheets on page out
      self.$f7.sheet.close();
      this.$f7.views.main.router.history = []; // Limpiamos historico
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
