import db from '../../services/Database';

export default {
  getAll() {
    return db.employees.toArray();
  },
  async getEmployee(type, code) {
    const employees = await this.getAll();
    for (let i = 0; i < employees.length && (employees[i].auth !== undefined
      || employees[i].auth.length) > 0; i += 1) {
      for (let j = 0; j < employees[i].auth.length; j += 1) {
        if (employees[i].auth[j].code === code && employees[i].auth[j].type === type) {
          return employees[i];
        }
      }
    }
    return null;
  },
  async getDrivers() {
    const employee = await db.employees.toArray();
    const response = employee.filter((obj) => obj.properties.some((item) => item.key === 'drive' && item.value === 'True'));
    return response;
  },
  async getEmployeeById(idEmployee) {
    const employee = await db.employees.where({ id: idEmployee }).toArray();
    if (employee.length > 0) {
      return employee[0];
    }
    return null;
  },
  async getEmployeeByCode(codeEmployee) {
    const employee = await db.employees.where({ code: codeEmployee }).toArray();
    if (employee.length > 0) {
      return employee[0];
    }
    return null;
  },
  async getByFullname(fullname = '') {
    const arrayfullname = fullname.split(' ');
    let employee;
    if (arrayfullname.length > 1) {
      employee = await db.employees.filter((emp) => emp.name.toUpperCase() === arrayfullname[0].toUpperCase()
      && emp.surname.toUpperCase().includes(arrayfullname[1].toUpperCase())).toArray();
    } else {
      employee = await db.employees.filter((emp) => emp.name.toUpperCase().includes(fullname.toUpperCase())
      || emp.surname.toUpperCase().includes(fullname.toUpperCase())).toArray();
    }
    if (!employee.length) {
      return null;
    }
    return employee;
  },
  async exists(fullname) {
    const employee = await db.employees
      .filter((emp) => fullname.has(emp.name) || fullname.has(emp.surname)).toArray();
    return Boolean(employee.length);
  },
  async insert(data) {
    await db.employees.add(data);
    return true;
  },
};
