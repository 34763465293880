export default {
  namespaced: true,
  state: {
    id: 0,
    newTask: 0,
    task_name: null,
    task_code: null,
    workers: [],
    plan_workers: [],
    task_unit: null,
    unit_price: null,
    locations: null,
    locationId: 0,
    locationName: null,
    event: null,
    upSheet: false,
    activity: null,
    activityId: 0,
    farm: null,
    status: null,
    showRegisterProductivity: false,
    detail_task: [],
    planner: [],
    items: [],
    items_drivers: [],
    machinery_id: 0,
    machinery_performance: [],
    driver_id: 0,
    productivity: 0,
    observation: [],
    checkout_multiple: false,
    last_employee: [],
    attachment: [],
    attachment_vehicles: [],
    products: [],
    attachment_id: 0,
    tools: [],
    comment: '',
    area_effective: 0,
    productivity2: 0,
    short_id: '',
    nursery: [],
  },

  getters: {
    newTask: (state) => state.newTask,
    locations: (state) => state.locations,
    locationId: (state) => state.locationId,
    locationName: (state) => state.locationName,
    event: (state) => state.event,
    id: (state) => state.id,
    task_name: (state) => state.task_name,
    task_code: (state) => state.task_code,
    workers: (state) => state.workers,
    plan_workers: (state) => state.plan_workers,
    task_unit: (state) => state.task_unit,
    task_price: (state) => state.task_price,
    upSheet: (state) => state.upSheet,
    activity: (state) => state.activity,
    activityId: (state) => state.activityId,
    farm: (state) => state.farm,
    status: (state) => state.status,
    detail_task: (state) => state.detail_task,
    planner: (state) => state.planner,
    items: (state) => state.items,
    items_drivers: (state) => state.items_drivers,
    machinery_id: (state) => state.machinery_id,
    machinery_performance: (state) => state.machinery_performance,
    driver_id: (state) => state.driver_id,
    productivity: (state) => state.productivity,
    observation: (state) => state.observation,
    checkout_multiple: (state) => state.checkout_multiple,
    last_employee: (state) => state.last_employee,
    attachment: (state) => state.attachment,
    attachment_id: (state) => state.attachment_id,
    comment: (state) => state.comment,
    tools: (state) => state.tools,
    products: (state) => state.products,
    attachment_vehicles: (state) => state.attachment_vehicles,
    area_effective: (state) => state.area_effective,
    productivity2: (state) => state.productivity2,
    short_id: (state) => state.short_id,
    nursery: (state) => state.nursery,
  },

  actions: {
    setAreaEffective(context, value) {
      context.commit('setAreaEffective', value);
    },
    setAttachmentVechicles(context, value) {
      context.commit('setAttachmentVechicles', value);
    },
    setProducts(context, value) {
      context.commit('setProducts', value);
    },
    setTools(context, value) {
      context.commit('setTools', value);
    },
    setComment(context, value) {
      context.commit('setComment', value);
    },
    setAttachmentId(context, value) {
      context.commit('setAttachmentId', value);
    },
    setAttachment(context, value) {
      context.commit('setAttachment', value);
    },
    setLastEmployee(context, value) {
      context.commit('setLastEmployee', value);
    },
    setCheckoutMultiple(context, value) {
      context.commit('setCheckoutMultiple', value);
    },
    setObservation(context, value) {
      context.commit('setObservation', value);
    },
    setProductivity(context, value) {
      context.commit('setProductivity', value);
    },
    setDriverId(context, value) {
      context.commit('setDriverId', value);
    },
    setMachineryPerformance(context, value) {
      context.commit('setMachineryPerformance', value);
    },
    setMachineryId(context, value) {
      context.commit('setMachineryId', value);
    },
    setDetailTask(context, value) {
      context.commit('setDetailTask', value);
    },
    setPlanWorkers(context, value) {
      context.commit('setPlanWorkers', value);
    },
    setPlanner(context, value) {
      context.commit('setPlanner', value);
    },
    setStatus(context, value) {
      context.commit('setStatus', value);
    },
    setLocations(context, value) {
      context.commit('setLocations', value);
    },
    setLocationId(context, value) {
      context.commit('setLocationId', value);
    },
    setLocationName(context, value) {
      context.commit('setLocationName', value);
    },
    setNewTask(context, value) {
      context.commit('setNewTask', value);
    },
    setId(context, value) {
      context.commit('setId', value);
    },
    changeUpSheet(context, value) {
      context.commit('changeUpSheet', value);
    },
    setTaskName(context, value) {
      context.commit('setTaskName', value);
    },
    setTaskCode(context, value) {
      context.commit('setTaskCode', value);
    },
    setWorkers(context, value) {
      context.commit('setWorkers', value);
    },
    setTaskUnit(context, value) {
      context.commit('setTaskUnit', value);
    },
    setTaskPrice(context, value) {
      context.commit('setTaskPrice', value);
    },
    setEvent(context, value) {
      context.commit('setEvent', value);
    },
    setActivity(context, value) {
      context.commit('setActivity', value);
    },
    setActivityId(context, value) {
      context.commit('setActivityId', value);
    },
    setFarm(context, value) {
      context.commit('setFarm', value);
    },
    setItems(context, value) {
      context.commit('setItems', value);
    },
    setItemsDrivers(context, value) {
      context.commit('setItemsDrivers', value);
    },
    changeShowRegisterProductivity(context) {
      context.commit('changeShowRegisterProductivity');
    },
    setProductivity2(context, value) {
      context.commit('setProductivity2', value);
    },
    setShortId(context, value) {
      context.commit('setShortId', value);
    },
    setNursery(context, value) {
      context.commit('setNursery', value);
    },
  },

  mutations: {
    setAreaEffective(state, user) {
      state.area_effective = user;
    },
    setAttachmentVechicles(state, user) {
      state.attachment_vehicles = user;
    },
    setProducts(state, user) {
      state.products = user;
    },
    setTools(state, user) {
      state.tools = user;
    },
    setComment(state, user) {
      state.comment = user;
    },
    setAttachmentId(state, user) {
      state.attachment_id = user;
    },
    setAttachment(state, user) {
      state.attachment = user;
    },
    setLastEmployee(state, user) {
      state.last_employee = user;
    },
    setCheckoutMultiple(state, user) {
      state.checkout_multiple = user;
    },
    setObservation(state, user) {
      state.observation = user;
    },
    setProductivity(state, user) {
      state.productivity = user;
    },
    setDriverId(state, user) {
      state.driver_id = user;
    },
    setMachineryPerformance(state, user) {
      state.machinery_performance = user;
    },
    setMachineryId(state, user) {
      state.machinery_id = user;
    },
    setDetailTask(state, user) {
      state.detail_task = user;
    },
    setPlanWorkers(state, user) {
      state.plan_workers = user;
    },
    setPlanner(state, user) {
      state.planner = user;
    },
    setStatus(state, user) {
      state.status = user;
    },
    setLocations(state, user) {
      state.locations = user;
    },
    setLocationId(state, user) {
      state.locationId = user;
    },
    setLocationName(state, user) {
      state.locationName = user;
    },
    setNewTask(state, user) {
      state.newTask = user;
    },
    changeUpSheet(state) {
      state.upSheet = !state.upSheet;
    },
    setId(state, user) {
      state.id = user;
    },
    setTaskName(state, user) {
      state.task_name = user;
    },
    setTaskCode(state, user) {
      state.task_code = user;
    },
    setWorkers(state, user) {
      state.workers = user;
    },
    setTaskUnit(state, user) {
      state.task_unit = user;
    },
    setTaskPrice(state, user) {
      state.task_price = user;
    },
    setEvent(state, event) {
      state.event = event;
    },
    setActivity(state, user) {
      state.activity = user;
    },
    setActivityId(state, user) {
      state.activityId = user;
    },
    setFarm(state, user) {
      state.farm = user;
    },
    setItems(state, user) {
      state.items = user;
    },
    setItemsDrivers(state, user) {
      state.items_drivers = user;
    },
    changeShowRegisterProductivity(state) {
      state.showRegisterProductivity = !state.showRegisterProductivity;
    },
    setProductivity2(state, user) {
      state.productivity2 = user;
    },
    setShortId(state, value) {
      state.short_id = value;
    },
    setNursery(state, value) {
      state.nursery = value;
    },
  },
};
