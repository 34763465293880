<template>
  <f7-page
    name="nfcPage"
    @page:beforein="beforein"
    @page:beforeout="beforeout"
  >
    <navbar
      :new-icon-enable="true"
      :text="$t('Nfc_navbarpresence_incidence')"
      :path-back="pathBack"
    />
    <f7-page-content>
      <f7-block class="text-align-center">
        <f7-icon icon="icon-scan" />
      </f7-block>
      <f7-block name="info">
        <div class="content-block text-align-center">
          <f7-block-title medium>{{ $t("Nfc_info_txtTitle") }}</f7-block-title>
          <p>{{ $t("Nfc_info_txtSubTitle") }}</p>
        </div>
      </f7-block>
      <!-- <f7-col>
        <f7-button
          name="btnChangePassword"
          class="botonPrimary"
          col
          button
          button-large
          button-raised
          fill
          @click="virtualNFC('80498F1A4890041')"
        >virtual nfc</f7-button>
      </f7-col> -->
    </f7-page-content>
    <BottomPanelCheckInOut />
  </f7-page>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import navbar from '../../components/NavBar.vue';
import EventBus from '../../js/event-bus';
import BottomPanelCheckInOut from '../../components/BottomPanelCheckInOut.vue';
import Api from '../../services/Api';

export default {
  name: 'NfcPage',

  components: {
    navbar,
    BottomPanelCheckInOut,
  },

  data() {
    return {
      code: '',
      manualAccessControl: false,
      eventAction: (newId) => this.manualAccessAction(newId),
      pathBack: '/accessPage/',
    };
  },
  computed: {
    scanDetailsOpened() {
      return this.persona !== null || this.personNotExists === true;
    },
    ...mapState('worker', ['selectedWorker', 'event', 'upSheet']),
  },
  methods: {
    beforein() {
      this.updateStateSheet();
      this.subscribeNFC();
    },
    beforeout() {
      EventBus.$off('nfc-scan', this.scanNFC);
    },
    subscribeNFC() {
      EventBus.$on('nfc-scan', this.scanNFC);
    },
    scanNFC(tag) {
      this.access('NFC', tag);
    },
    modalInfoClosed() {
      this.closeModal();
    },
    async access(type, code) {
      this.$f7.preloader.show();
      this.code = code;
      try {
        const xhr = await Api.access(type, code);
        const json = JSON.parse(xhr.response);
        this.setSelectedWorker(json);
        this.setEvent(json.event);
      } catch (error) {
        const event = { type: 'ERROR', status: error };
        this.setEvent(event);
        this.changeUpSheet();
        this.updateStateSheet();
      } finally {
        this.$f7.preloader.hide();
      }
    },
    updateStateSheet() {
      if (this.upSheet === true) {
        this.$f7.sheet.open('.demo-sheet-swipe-to-step');
        this.changeUpSheet();
      }
    },
    virtualNFC(tag) {
      this.access('NFC', tag);
    },
    ...mapActions('worker', ['changeUpSheet', 'setSelectedWorker', 'setEvent']),
  },
};
</script>
<style lang="scss" scoped>
.page-content {
  color: #9b9b9b !important;

  .block-title-medium {
    color: #9b9b9b !important;
  }
}

div.demo-sheet.sheet-modal.sheet-modal-bottom.modal-in {
  height: 40%;
}
</style>
