<template>
  <f7-popup
    animate
    :opened="opened"
  >
    <f7-block
      name="infoUpdate"
      medium-inset
      class="padding"
    >
      <div class="label-text">
        {{ $t("SyncingModal_infoUpdate_title") }}
      </div>
      <div class="content-text">
        <p>
          {{ $t("SyncingModal_infoUpdate_text") }}
        </p>
      </div>
    </f7-block>
    <f7-block
      v-if="isSyncing"
      name="infoLoading"
    >
      <div class="content-block text-align-center">
        <div class="content-text">
          <f7-preloader />
          <p>
            <strong class="label-text">{{ $t("SyncingModal_infoLoading_title") }}</strong>
          </p>
          <small>
            {{ $t("SyncingModal_infoLoading_text") }}
          </small>
        </div>
      </div>
    </f7-block>
    <f7-block v-if="syncError">
      <p><strong>{{ $t("SyncingModal_txtError") }}</strong> {{ syncError }}.</p>
    </f7-block>
    <f7-block>
      <div class="content-block">
        <f7-button
          v-if="!isSyncing"
          name="btnSync"
          round
          large
          fill
          @click="$emit('syncRequested')"
        >
          {{ $t("SyncingModal_btnSync") }}
        </f7-button>
      </div>
    </f7-block>
  </f7-popup>
</template>
<script>
export default {
  name: 'SyncingModal',
  props: {
    opened: Boolean,
    syncError: { type: String, default: '' },
    isSyncing: Boolean,
  },
};
</script>

<style lang="scss" scoped>
div[name="infoUpdate"], div[name="infoLoading"] {
  text-align: center;
}
.label-text{
  font-size: 18px;
  text-align: center;
}
</style>
