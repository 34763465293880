// import Api from './Api';
import db from './Database';
// import Utils from '../js/utils';

export const TOPIC = 'SECURITY_ACCESS';
export const EVENT_TYPE_EMPLOYEE = 'EMPLOYEE';

export const OUTCOME_UNKNOWN = 'UNKNOWN';
export const OUTCOME_ISSUE = 'ISSUE';

export default {
  username: '',
  token: '',
  locationId: -1,
  identificationType: '',
  identification: '',

  getEventsPendingToSync() {
    // return db.modifiedRecords.where({ type: 'task' }).toArray();
    return db.modifiedRecords.where('type').anyOf([
      'task',
      'bin',
    ]).toArray();
  },

  // buildEvent(sent) {
  //   const empleado = {
  //     avatar: '',
  //     identificacion: '',
  //     nombreCompleto: '',
  //   };

  //   let query = { identificacion: this.identification };
  //   if (this.identificationType !== 'dni') {
  //     query = { photocheck: this.identification };
  //   }

  //   return db.employees.get(query)
  //     .then((employee) => (employee === undefined ? empleado : employee))
  //     .catch(() => empleado)
  //     .then((employee) => ({
  //       apellido1: '',
  //       apellido2: '',
  //       avatar: employee.avatar,
  //       dni: employee.identificacion,
  //       fechaFin: '',
  //       fechaInicio: '',
  //       idLocation: this.locationId,
  //       inicioOrdenacion: '',
  //       location: '',
  //       nombre: employee.nombreCompleto,
  //       qr: this.identification,
  //       type: this.identificationType,
  //       sent: sent ? 1 : 0,
  //       serverId: 0,
  //       timestamp: Date.now(),
  //       tipo: 'individual',
  //       tipoTickaje: 'Desconocido',
  //       incidencia: true,
  //       tipoIncidencia: 'Acceso sin conexión',
  //       uuid: Utils.uuidv4(),
  //     }));
  // },

  // async scanEmployee(locationId, identificationType, identification, tempEmployee) {
  //   this.locationId = locationId;
  //   this.identificationType = identificationType;
  //   this.identification = identification;
  //   const storedEvent = await this.addEmployeeToAccessControlEvents(tempEmployee, false);
  //   return Api.uploadPendingAccessEvent(locationId, identificationType, identification)
  //     .then(async (response) => {
  //       try {
  //         const { uuid } = storedEvent;
  //         await db.accessControl.where({ uuid }).delete();
  //       } catch (e) {
  //         // Si el UUID no existe, ignoramos el error
  //       }

  //       return response.result !== 'ok' ? Error(response.errorcode) : response.data;
  //     }).catch(() => {
  //       storedEvent.motivoIncidencia = 'Acceso sin conexión';
  //       return storedEvent;
  //     });
  // },

  // findEmployeeByIdentification(identificationType, identification) {
  //   const query = identificationType === 'dni'
  //     ? { identificacion: identification } : { photocheck: identification };

  //   const employee = {
  //     nombreEmpleado: '',
  //     apellido1Empleado: '',
  //     apellido2Empleado: '',
  //     urlFoto: '',
  //     identificacion: '',
  //     incidencia: 's',
  //     motivoIncidencia: 'Acceso sin conexión',
  //   };

  //   return db.employees.get(query)
  //     .then((result) => {
  //       employee.nombreEmpleado = result.nombreCompleto;
  //       employee.urlFoto = result.avatar;
  //       employee.identificacion = result.identificacion;
  //       return employee;
  //     }).catch(() => employee);
  // },

  // addEmployeeToAccessControlEvents(employee, sent) {
  //   return this.buildEvent(sent)
  //     .then((newEvent) => {
  //       db.accessControl.put(newEvent);
  //       employee.uuid = newEvent.uuid;
  //       return employee;
  //     });
  // },
};
