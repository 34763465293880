<template>
  <f7-page
    name="QrScanner"
    class="content-manual-list"
  >
    <navbar
      :text="`${$t('LeftPanel_Subtitle_ConsultBins')}`"
      :path-back="pathBack"
    />
  </f7-page>
</template>

<script>
// import { mapState } from 'vuex';
import navbar from '../components/NavBar.vue';

export default {
  name: 'QrScanner',
  components: {
    navbar,
  },

  data() {
    return {
      pathBack: '/bins/consult/',
    };
  },
  computed: {
  },
  methods: {
  },
};
</script>
<style lang="scss">
.content-manual-list {
    background: var(--f7-sheet-bg-color);
}
.content-manual-list .list ul:before,
.content-manual-list .list ul:after{
  display: none!important;
}
.content-manual-list .list ul {
  padding: 0px 0 10px;
}
.content-manual-list .item-input-outline .input-clear-button {
    right: 20px;
}
.content-manual-list .searchbar input[type="search"] {
  background: transparent!important;
}
</style>
