<template>
  <f7-page
    class="content-manual-list"
    @page:beforein="beforein"
  >
    <navbar
      :new-icon-enable="true"
      :text="`${activity} - ${machineryFamily}`"
      :path-back="`/${pathBack}/`"
    />
    <f7-block class="machinery-registration">
      <div>
        <div class="checkout-machinery-header">
          <div class="item-inner">
            <div class="item-title-row">
              <div class="item-title task-meta no-margin no-padding">
                {{ machineryFamily }}
              </div>
              <div class="item-subtitle text-color-chocolat" />
              <div class="item-after" />
            </div>
            <div class="item-subtitle">
              <div class="task-meta">
                <!-- <img
                    width="20"
                    height="20"
                    :src="images.ic_worker"
                > -->
                <span>{{ machineryName }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </f7-block>
    <FormInputFitosanitary />
  </f7-page>
</template>

<script>
import { mapState } from 'vuex';
import navbar from '../../components/NavBar.vue';
import FormInputFitosanitary from '../../components/tasks/FormInputFitosanitary.vue';

export default {
  components: {
    navbar,
    FormInputFitosanitary,
  },
  props: {
    pathBack: { type: String, default: '/tasks/list/', reloadcurrent: true },
  },
  data() {
    return {
      machineryName: '',
      machineryFamily: '',
    };
  },
  computed: {
    ...mapState('TaskManager', ['activity', 'id', 'task_name', 'attachment_id', 'attachment', 'planner']),
  },
  methods: {
    beforein() {
      this.$f7.preloader.show();
      // const attachment = await Items.getItemsById(this.attachment_id);
      // this.machineryName = attachment[0].name;
      // this.machineryFamily = attachment[0].subfamily;
      for (let i = 0; i < this.planner.products.length; i += 1) {
        if (this.planner.products[i].productName === this.attachment_id) {
          this.machineryName = this.planner.products[i].product.description;
          for (let j = 0; j < this.planner.products[i].product.properties.length; j += 1) {
            if (this.planner.products[i].product.properties[j].key === 'family') {
              this.machineryFamily = this.planner.products[i].product.properties[j].value;
            }
          }
        }
      }
      this.$f7.preloader.hide();
    },
  },
};
</script>

<style lang="scss">
.machinery-registration {
  border-bottom: 1px solid #D8D8D8;
  padding-bottom: 4%;
  padding-top: 4%;
  background: var(--f7-sheet-bg-color);
}
.content-manual-list {
    background: var(--f7-sheet-bg-color);
}
.content-task-header {
  display: flex;
  flex-direction: row;
  background: var(--f7-sheet-bg-color);
  padding: 10px 15px;
  border-bottom: 1px solid var(--f7-input-outline-border-color);
}

.checkout-machinery-header .item-inner:nth-of-type(1) {
  min-width: 100%;
  align-self: center;
}
.checkout-machinery-header .item-inner:nth-of-type(2) {
  min-width: 30%;
  align-self: center;
  text-align: center;
}

.checkout-machinery-header{
  .item-title {
    font-weight: normal;
    font-size: 14px;
    line-height: 1.2;
    color:var(--ha-gray);
    text-transform: capitalize;
    text-align: left;
    display: flex;
    align-items: center;
  }
  .item-subtitle {
    max-width: 80%;
    display: flex;
    text-transform: capitalize;
    font-size: 20px;
    line-height: 1.2;
    margin-top: 3px;
    font-weight: 500;
    color: var(--fr-chocolat);
  }
}

.checkout-machinery-header .task-meta span {
  display: inline-block;
}

</style>
