<template>
  <f7-page
    class="main-checkoutmulti"
    @page:beforein="beforein"
  >
    <navbar
      :new-icon-enable="true"
      :text="`${activity} - ${$t('Nfc_navbarTask_checkout')}`"
      :path-back="`/${pathBack}/`"
    />
    <div>
      <div class="content-task-header">
        <div class="item-inner">
          <div class="item-title-row">
            <div class="item-title text-color-black no-margin no-padding center">
              {{ task_name }}
            </div>
            <div class="item-subtitle text-color-chocolat">
              {{ getLocations(addLocation) }} ⇢
              <strong class="text-color-nutella">
                {{ activity }}
              </strong>
            </div>
            <div class="item-after" />
          </div>
          <div class="item-subtitle">
            <div class="task-meta">
              <img
                width="20"
                height="20"
                :src="images.ic_worker"
              >
              <span>{{ countIn(workers) }}/{{ count(plan_workers) }}</span>
              <img
                width="20"
                height="20"
                :src="images.ic_timetask"
              >
              <span>{{ timeOfWorked() }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <f7-row>
      <f7-col>
        <div class="checkout-productivity-help multiple">
          <img
            class="margin-right"
            width="48"
            height="48"
            :src="images.ic_multiple"
          >
          <span>{{ $t("checkOut_assigned") }} <b>{{ $t("checkOut_assigned_multiple") }}</b></span>
        </div>
      </f7-col>
    </f7-row>
    <f7-block
      class="checkout-productivity"
    >
      <f7-list no-hairlines-md>
        <f7-row class="content-usario">
          <f7-col width="50">
            <div v-if="planner.goal_performance.unit !== ''" class="content-stepper text-align-center">
              <div class="content-stepper__title">
                {{ $t("checkOut_Productivity") }} {{ planner.goal_performance.unit.toUpperCase() }}
              </div>
              <f7-list-input
                type="number"
                :value="productivityValue"
                @input="setProductivityValue($event.target.value)"
              />
            </div>
          </f7-col>
          <f7-col width="50">
            <div
              v-if="typeof planner.goal_performance.working_area !== 'undefined'"
              class="content-stepper text-align-center"
            >
              <div class="content-stepper__title">
                {{ $t("checkOut_workingArea") }} HA
              </div>
              <f7-list-input
                type="number"
                :value="productivityValue2"
                @input="setProductivityValue2($event.target.value)"
              />
            </div>
          </f7-col>
        </f7-row>
        <f7-row v-if="typeof planner.goal_performance.working_area !== 'undefined'">
          <f7-col width="100">
            <div class="item-label smart-title">
              {{ $t("StartOrder_productivity_working_area_goal") }}
            </div>
            <f7-list
              no-hairlines-md
              style="margin: 0px !important"
            >
              <f7-list-item
                :title="`${$t('StartOrder_productivity_working_area_goal')}`"
                smart-select
                :smart-select-params="{ openIn: 'popover' }"
              >
                <select
                  id="difficulty"
                  name="difficulty"
                  class="input-field"
                  @change="selectDifficulty($event)"
                >
                  <option
                    :value="planner.goal_performance.working_area"
                    selected
                  >
                    {{ planner.goal_performance.working_area }} HA
                  </option>
                </select>
              </f7-list-item>
            </f7-list>
          </f7-col>
        </f7-row>
        <f7-row v-if="planner.goal_performance.unit !== ''">
          <f7-col width="100">
            <div class="item-label smart-title">
              {{ $t("StartOrder_productivity_goal") }}
            </div>
            <f7-list
              no-hairlines-md
              style="margin: 0px !important;"
            >
              <f7-list-item
                :title="`${$t('CheckOutMulti_note_lable')}`"
                smart-select
                :smart-select-params="{openIn: 'popover'}"
              >
                <select
                  id="difficulty"
                  :name="`${$t('StartOrder_productivity_goal')}`"
                  class="input-field"
                  @change="selectDifficulty($event)"
                >
                  <option
                    key="EASY"
                    :value="planner.goal_performance.unit"
                  >
                    {{ planner.goal_performance.count }} {{ planner.goal_performance.unit }}
                  </option>
                </select>
              </f7-list-item>
            </f7-list>
          </f7-col>
        </f7-row>
        <f7-row>
          <f7-col width="100">
            <div class="item-label smart-title">
              {{ $t("checkOut_observations") }}
            </div>
            <div class="item-input-wrap">
              <textarea
                class="resizable"
                maxlength="50"
                @input="addComment($event.target.value)"
              />
            </div>
          </f7-col>
        </f7-row>
      </f7-list>
    </f7-block>
    <f7-block>
      <f7-button
        name="btnContinue"
        class="botonPrimary color-green"
        col
        button
        button-large
        button-raised
        fill
        :disabled="btnDisable"
        @click="setFullNameFilter"
      >
        {{ $t("Nfc_navbarpresence_btnContinue") }}
      </f7-button>
    </f7-block>

    <BottomPanelCheckInOut />
  </f7-page>
</template>

<script>
// import ImageCheckOut from "../../static/img/imgCheckOut.png";
import moment from 'moment';
import { mapActions, mapState } from 'vuex';
import navbar from '../../components/NavBar.vue';
import ImageTime from '../../static/img/ic_time_task.svg';
import img from '../../static/img/ic_user_enter.svg';
import ImgIndividual from '../../static/img/ic_individual.svg';
import ImgGroup from '../../static/img/ic_group.svg';
import ImageUserTask from '../../static/img/ic_user_task.svg';
import TasksSync from '../../js/models/TasksSync';
import BottomPanelCheckInOut from '../../components/BottomPanelCheckInOut.vue';
import Locations from '../../js/models/Locations';

export default {
  components: {
    navbar,
    BottomPanelCheckInOut,
  },
  props: {
    pathBack: { type: String, default: 'tasks/workersSelection' },
  },
  data() {
    return {
      value: '',
      workerAux: [],
      productivityValue: 0,
      productivityValue2: 0,
      btnDisable: true,
      fullNameFilter: 0,
      selectUnits: '',
      units: [
        { name: 'man/day' },
        { name: 'ha' },
        { name: 'm' },
        { name: 'plants' },
        { name: 'hours' },
        { name: 'm2' },
      ],
      images: {
        ic_timetask: ImageTime,
        ic_individual: ImgIndividual,
        ic_multiple: ImgGroup,
        ic_worker: ImageUserTask,
      },
      workerSelect: 0,
      noteValue: '',
      addLocation: [],
    };
  },
  computed: {
    ...mapState('TaskManager', ['id', 'workers', 'task_name', 'activity', 'farm', 'plan_workers', 'locationId', 'planner', 'items', 'attachment', 'locationId']),
    ...mapState('worker', ['type', 'code', 'selectedWorker']),
    ...mapState('AccessControl', ['currentFarmCode']),
  },
  methods: {
    async beforein() {
      this.locationsTree = await Locations.getAll();
      for (let i = 0; i < this.locationId.length; i += 1) {
        this.checkRestChild(this.locationId[i], this.locationsTree);
      }
    },
    checkRestChild(item, locationsTree) {
      for (let i = 0; i < locationsTree.length; i += 1) {
        if (locationsTree[i].children.length > 0) {
          for (let j = 0; j < locationsTree[i].children.length; j += 1) {
            if (locationsTree[i].children[j].children.length > 0) {
              for (let k = 0; k < locationsTree[i].children[j].children.length; k += 1) {
                if (locationsTree[i].children[j].children[k].children.length > 0) {
                  for (let l = 0; l < locationsTree[i].children[j].children[k].children.length; l += 1) {
                    if (locationsTree[i].children[j].children[k].children[l].children.length > 0) {
                      for (let m = 0; m < locationsTree[i].children[j].children[k].children[l].children.length; m += 1) {
                        if (locationsTree[i].children[j].children[k].children[l].children[m].bd_id === item) {
                          this.addLocation.push(locationsTree[i].children[j].children[k].name);
                          for (let n = 0; n < this.addLocation.length - 1; n += 1) {
                            if (this.addLocation[n] === this.addLocation[n + 1]) {
                              this.addLocation.splice(n, 1);
                            }
                          }
                        }
                      }
                    } else if (locationsTree[i].children[j].children[k].children[l].bd_id === item) {
                      this.addLocation.push(locationsTree[i].children[j].children[k].name);
                      for (let n = 0; n < this.addLocation.length - 1; n += 1) {
                        if (this.addLocation[n] === this.addLocation[n + 1]) {
                          this.addLocation.splice(n + 1, 1);
                        }
                      }
                    }
                  }
                } else if (locationsTree[i].children[j].children[k].bd_id === item) {
                  this.addLocation.push(locationsTree[i].children[j].children[k].name);
                  for (let n = 0; n < this.addLocation.length - 1; n += 1) {
                    if (this.addLocation[n] === this.addLocation[n + 1]) {
                      this.addLocation.splice(n + 1, 1);
                    }
                  }
                }
              }
            } else if (locationsTree[i].children[j].bd_id === item) {
              this.addLocation.push(locationsTree[i].children[j].name);
              for (let n = 0; n < this.addLocation.length - 1; n += 1) {
                if (this.addLocation[n] === this.addLocation[n + 1]) {
                  this.addLocation.splice(n + 1, 1);
                }
              }
            }
          }
        } else if (locationsTree[i].bd_id === item) {
          this.addLocation.push(locationsTree[i].name);
          for (let n = 0; n < this.addLocation.length - 1; n += 1) {
            if (this.addLocation[n] === this.addLocation[n + 1]) {
              this.addLocation.splice(n, 1);
            }
          }
        }
      }
      this.addLocation = this.addLocation.filter(this.onlyUnique);
    },
    onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    },
    addComment(value) {
      this.noteValue = value;
    },
    getLocations(item) {
      let aux = '';
      if (item.length > 0) {
        for (let i = 0; i < item.length; i += 1) {
          if (i === 0) aux += `${item[i]}`;
          else aux += `, ${item[i]}`;
        }
      }
      return aux;
    },
    timeOfWorked() {
      let totalTime = 0;
      for (let i = 0; i < this.workers.length; i += 1) {
        const checkinDate = moment(this.workers[i].init_date);
        if (this.workers[i].end_date !== null) {
          const checkoutDate = moment(this.workers[i].end_date);
          const duration = moment.utc((moment.duration(checkoutDate.diff(checkinDate)).asMilliseconds()));
          if (totalTime < duration) {
            totalTime = duration;
          }
        } else {
          let checkoutDate = moment(new Date());
          checkoutDate = checkoutDate.format('YYYY-MM-DD HH:mm:ss');
          checkoutDate = moment.utc(checkoutDate);
          const duration = moment.utc((moment.duration(checkoutDate.diff(checkinDate)).asMilliseconds()));
          if (totalTime < duration) {
            totalTime = duration;
          }
        }
      }
      return moment.utc(totalTime).format('HH:mm');
    },
    onChangeNote(e) {
      this.noteValue = e.target.value;
    },
    setProductivityValue(value) {
      this.productivityValue = value;
    },
    setProductivityValue2(value) {
      this.productivityValue2 = value;
      if (this.planner.goal_performance.unit === '') {
        this.btnDisable = this.productivityValue2 < 0.1;
      } else {
        this.btnDisable = this.productivityValue2 < 0.1;
      }
    },
    async setFullNameFilter() {
      if (this.noteValue.length <= 50) { // La nota tiene máximo 50 caracteres
        this.$f7.preloader.show();
        this.setTaskUnit(this.productivityValue);
        this.setProductivity2(this.productivityValue2);
        // TODO: Hay que revisar que no sea siempre 'm'.
        this.fullNameFilter = 'm';
        if (this.items.length > 0) {
          this.setCheckoutMultiple(true);
          this.setMachineryId(this.items[0].item);
          this.setObservation(this.noteValue);
          if (this.attachment.length > 0) {
            this.setAttachmentId(this.attachment[0].item_id);
          }
          this.setMachineryPerformance([]);
          this.$f7.views.main.router.navigate('/tasks/RegistrationMachineryData/', { reloadCurrent: false });
        } else if (this.attachment.length > 0) {
          this.setCheckoutMultiple(true);
          this.setObservation(this.noteValue);
          this.setAttachmentId(this.attachment[0].item_id);
          this.$f7.views.main.router.navigate('/tasks/RegistrationFitosanitaryData/', { reloadCurrent: false });
        } else {
          try {
            const response = await TasksSync.checkOutGroup(
              this.id,
              this.productivityValue,
              this.productivityValue2,
              this.noteValue,
              this.planner.goal_performance.unit,
            );
            this.setEvent({ type: 'OUT', status: 'All workers are out' });
            this.setWorkers(response.workers);
            this.$f7.sheet.open('.demo-sheet-swipe-to-step');

            this.$f7.views.main.router.navigate('/tasks/ExportTask/', { reloadCurrent: false });
            // reloadCurrent: false para que el popup se pueda cerrar al pulsar en cualquier sitio
          } catch (error) {
            console.log(error); // TODO: Mostrar info del error
            this.$f7.dialog.alert(error);
          } finally {
            this.$f7.preloader.hide();
          }
        }
      } else {
        this.$f7.dialog.alert(`${this.$t('CheckOutMulti_note_alert')} ${this.noteValue.length}`);
      }
    },
    formattedHour(dt) {
      const ms = moment().diff(moment(dt));
      const d = moment.duration(ms);
      return `${d.hours()}:${d.minutes()}`;
    },

    changeInput(inputValue) {
      this.fullNameFilter = inputValue;
      this.btnDisable = (this.fullNameFilter === '');
    },
    photoSrc() {
      return img;
    },
    workerInit() {
      let aux = 0;
      if (this.workerSelect < 1) {
        for (let i = 0; i < this.workers.length; i += 1) {
          if (this.workers[i].end_date === null) {
            this.workerAux.push(
              this.workers[i].id,
            );
            aux += 1;
          }
        }
      }
      this.workerSelect += 1;
      return aux;
    },
    count(item) {
      let x = 0;
      for (let i = 0; i < item.length; i += 1) {
        x += item[i].count;
      }
      return x;
    },
    countIn(item) {
      let x = 0;
      for (let i = 0; i < item.length; i += 1) {
        if (item[i].end_date === null) {
          x += 1;
        }
      }
      return x;
    },
    ...mapActions('TaskManager', ['setProductivity2', 'setTaskUnit', 'setWorkers', 'setMachineryId', 'setDriverId', 'setMachineryPerformance', 'setObservation', 'setCheckoutMultiple', 'setAttachmentId']),
    ...mapActions('worker', ['changeUpSheet', 'setEvent', 'changeUpSheet']),
  },
};
</script>
<style lang="scss">
.main-checkoutmulti{
  .checkout-productivity-help {
    position: relative;
    display: flex;
    align-items: center;
    padding: 10px;
    margin: 15px 15px 0;
    background: #EDF5FE;
    border-radius: 6px;
    line-height: 1.4;
    padding-right: 40px;
    color: #556080;
    filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.13));
  }
  .checkout-productivity-help.multiple {
    background: #EDF5FE;
  }

  .checkout-productivity-help::after {
      content: "";
      position: absolute;
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      border-top: 12px solid #EDF5FE;
      right: 65px;
      bottom: -11px;
  }

  .checkout-productivity div.col:nth-child(1) {
    width: 60%;
    align-self: center;
  }

  .checkout-productivity div.col:nth-child(2) {
    width: 40%;
    align-self: center;
  }

  .checkout-productivity .user-id {
    display: flex;
    align-items: center;
    padding: 20px 16px 20px 20px;
    float: right;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.2;
  }

  .checkout-productivity .user-id img {
    margin-right: 8px;
  }

  .ios .stepper .stepper-input-wrap input {
    width: 64px;
  }

  .content-task-header {
    display: flex;
    flex-direction: row;
    background: var(--f7-sheet-bg-color);
    padding: 10px 15px;
    border-bottom: 1px solid var(--f7-input-outline-border-color);
  }

  .content-task-header .item-inner:nth-of-type(1) {
    min-width: 75%;
    align-self: center;
  }
  .content-task-header .item-inner:nth-of-type(2) {
    min-width: 30%;
    align-self: center;
    text-align: center;
  }

  .content-task-header .item-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 1.2;
    color: #2E2E2E;
    text-transform: capitalize;
    white-space: initial;
    text-align: left;
    display: flex;
    align-items: center;
  }

  .content-task-header .item-subtitle {
    max-width: 75%;
    text-transform: capitalize;
    font-size: 12px;
    line-height: 1.4;
    margin-top: 3px;
  }

  .content-task-header .task-meta {
    display: flex;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    margin-top: 8px;
    color: var(--fr-chocolat);
  }

  .content-task-header .task-meta span {
    display: inline-block;
    margin: 0px 20px 0px 6px;
  }

  .content-task-header .task-meta img {
    float: left;
  }

  .content-task-header .button {
    box-shadow: none;
    border: none;
    background-color: transparent;
    border-radius: 0;
    height: 68px;
  }

  div.input.input-with-value{
    padding-top: 4%;
    padding-left: 8%;
  }
  div.item-label.smart-title {
    margin-left: 5%;
    margin-right: 5%;
    font-style: normal;
    font-weight: normal;
    height: 19px;
  }
  .item-content.item-input.item-input-with-value input[type="number"]{
    min-height: 58px;
    border-radius: 8px;
    border-color: var(--ha-silver) !important;
    color: var(--fr-kinder)!important;
    background-color: white;
    font-weight: 500;
    font-size: 18px;
  }
  .item-input-wrap:after{
    height: 0!important;
  }
  div.block-title{
    line-height: 34px;
    text-align: center;
    font-size: 30px;
    color: darkslateblue;
  }
  .color{
    background: #F7F8FA;
    border: 1px solid #D4DCE1;
    border-radius: 8px;
    height: 59px;
    width: 50%;
    left: 16px;
  }
  .list.item-input-outline.item-floating-label {
    background: #f7f8fa !important;
    width: 70% !important;
  }

  .header {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
  }
  .avatar{
    margin-top: 15px;
  }
}

</style>
